<template>
  <div v-if="backtest">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="card bg-card rounded-lg p-6 border border-base-300">
        <h3 class="text-lg font-semibold mb-4">Backtest Information</h3>
        <div class="space-y-4">
          <div class="flex justify-between">
            <span class="text-muted">Name</span>
            <span class="font-medium">{{ backtest.name }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-muted">Author</span>
            <span class="font-medium">{{ backtest.author?.full_name }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-muted">Created At</span>
            <span class="font-medium">{{
              formatDate(backtest.created_at)
            }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-muted">Updated At</span>
            <span class="font-medium">{{
              formatDate(backtest.updated_at)
            }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-muted">Run ID</span>
            <span class="font-medium text-sm break-all">{{
              backtest.run_id
            }}</span>
          </div>
        </div>
      </div>

      <div class="card bg-card rounded-lg p-6 border border-base-300">
        <h3 class="text-lg font-semibold mb-4">Attachments</h3>
        <div class="space-y-4">
          <div
            v-for="attachment in backtest.attachments"
            :key="attachment.id"
            class="flex justify-between items-center"
          >
            <div class="flex flex-col">
              <span class="font-medium">{{ attachment.name }}</span>
              <span class="text-muted text-sm">{{
                formatFileSize(attachment.file_size)
              }}</span>
            </div>
            <a
              :href="attachment.file_url"
              target="_blank"
              class="btn btn-sm btn-primary"
            >
              Download
            </a>
          </div>
          <div
            v-if="!backtest.attachments?.length"
            class="text-muted text-center"
          >
            No attachments available
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/formatting";

export default {
  name: "BacktestOverviewTab",
  props: {
    backtest: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const formatFileSize = (bytes) => {
      if (!bytes) return "N/A";
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "0 Byte";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    };

    return {
      formatDate,
      formatFileSize,
    };
  },
};
</script>
