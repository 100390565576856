<template>
  <div
    v-if="totalPages > 1"
    class="join"
  >
    <button
      class="join-item btn"
      :disabled="currentPage === 1"
      @click="$emit('page-change', 1)"
    >
      «
    </button>
    <button
      class="join-item btn"
      :disabled="currentPage === 1"
      @click="$emit('page-change', currentPage - 1)"
    >
      Previous
    </button>
    <template
      v-for="page in visiblePages"
      :key="page"
    >
      <button
        v-if="page !== '...'"
        class="join-item btn"
        :class="{ 'btn-active': page === currentPage }"
        @click="$emit('page-change', page)"
      >
        {{ page }}
      </button>
      <span
        v-else
        class="join-item btn btn-disabled"
      >{{ page }}</span>
    </template>
    <button
      class="join-item btn"
      :disabled="currentPage === totalPages"
      @click="$emit('page-change', currentPage + 1)"
    >
      Next
    </button>
    <button
      class="join-item btn"
      :disabled="currentPage === totalPages"
      @click="$emit('page-change', totalPages)"
    >
      »
    </button>
  </div>
  <div
    v-else
    class="join"
  >
    <button
      class="join-item btn"
      disabled
    >
      1
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  emits: ["page-change"],
  computed: {
    visiblePages() {
      const delta = 2; // Number of pages to show on each side of the current page
      let range = [];
      for (
        let i = Math.max(2, this.currentPage - delta);
        i <= Math.min(this.totalPages - 1, this.currentPage + delta);
        i++
      ) {
        range.push(i);
      }

      if (this.currentPage - delta > 2) {
        range.unshift("...");
      }
      if (this.currentPage + delta < this.totalPages - 1) {
        range.push("...");
      }

      range.unshift(1);
      if (this.totalPages !== 1) {
        range.push(this.totalPages);
      }

      return range;
    },
  },
};
</script>
