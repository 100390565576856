import axios from "axios";

export default {
  namespaced: true,

  state: {
    prices: {}, // Will store coin:price pairs dynamically
    lastFetchTime: 0,
    CACHE_DURATION: 15000,
    MAX_RETRIES: 3,
    RETRY_DELAY: 2000,
    pollInterval: null,
    isLoading: false,
    error: null,
    isInitialized: false,
  },

  getters: {
    currentBTCPrice: (state) => state.prices.btc || null,

    getPrice: (state) => (symbol) => state.prices[symbol.toLowerCase()] || 0,

    getAllPrices: (state) => {
      return state.prices;
    },

    isPriceFresh: (state) =>
      Date.now() - state.lastFetchTime < state.CACHE_DURATION,

    hasError: (state) => state.error !== null,
    isLoading: (state) => state.isLoading,

    isInitialized: (state) => Object.keys(state.prices).length > 0,
  },

  mutations: {
    SET_PRICES(state, prices) {
      if (!prices) {
        return;
      }

      // Store the prices data directly
      state.prices = prices;
      state.lastFetchTime = Date.now();
      state.error = null;
    },

    SET_POLL_INTERVAL(state, interval) {
      state.pollInterval = interval;
    },

    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },

    SET_ERROR(state, error) {
      state.error = error;
    },

    SET_INITIALIZED(state, value) {
      state.isInitialized = value;
    },

    RESET_STATE(state) {
      state.prices = {};
      state.lastFetchTime = 0;
      state.error = null;
      state.isLoading = false;
      state.isInitialized = false;
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
        state.pollInterval = null;
      }
    },
  },

  actions: {
    async initializePrice({ dispatch, commit, state }) {
      if (state.isInitialized) {
        return;
      }

      commit("RESET_STATE");

      try {
        await dispatch("fetchPrices");
        dispatch("startPolling");
        commit("SET_INITIALIZED", true);
      } catch (error) {
        commit("SET_ERROR", "Failed to initialize price data");
      }
    },

    async fetchWithRetry({ dispatch, state }, attempt = 1) {
      try {
        const response = await axios.get("/api/market/price/");

        if (!response.data) {
          throw new Error("Empty response from API");
        }

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        // Normalize the price data format
        const normalizedPrices = {};
        Object.entries(response.data).forEach(([key, value]) => {
          const normalizedKey = key.split("/")[0].toLowerCase();
          normalizedPrices[normalizedKey] = value;
        });

        // Ensure we have at least one valid price
        const hasValidPrice = Object.values(normalizedPrices).some(
          (value) => typeof value === "number" && !isNaN(value)
        );

        if (!hasValidPrice) {
          throw new Error("No valid prices found in response");
        }

        return normalizedPrices;
      } catch (error) {
        if (attempt < state.MAX_RETRIES) {
          await new Promise((resolve) =>
            setTimeout(resolve, state.RETRY_DELAY)
          );
          return dispatch("fetchWithRetry", attempt + 1);
        }
        throw error;
      }
    },

    async fetchPrices({ commit, state, getters, dispatch }) {
      if (getters.isPriceFresh) {
        return state.prices;
      }

      commit("SET_LOADING", true);
      commit("SET_ERROR", null);

      try {
        const data = await dispatch("fetchWithRetry");
        commit("SET_PRICES", data);
        return data;
      } catch (error) {
        commit("SET_ERROR", "Failed to fetch latest prices");
        return state.prices;
      } finally {
        commit("SET_LOADING", false);
      }
    },

    startPolling({ commit, dispatch, state }) {
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
        commit("SET_POLL_INTERVAL", null);
      }

      dispatch("fetchPrices");

      const interval = setInterval(async () => {
        if (!state.isLoading) {
          await dispatch("fetchPrices");
        }
      }, state.CACHE_DURATION);

      commit("SET_POLL_INTERVAL", interval);
    },

    cleanup({ commit }) {
      commit("RESET_STATE");
    },
  },
};
