<template>
  <div>
    <h2 class="text-2xl font-semibold mb-4">Order Fills</h2>

    <!-- Loading and Error States -->
    <div
      v-if="isLoading"
      class="text-center py-4"
    >
      Loading...
    </div>
    <div
      v-else-if="error"
      class="text-red-500 py-4"
    >
      {{ error }}
    </div>
    <template v-else>
      <ResponsiveTable
        :columns="tableColumns"
        :data="orders"
      >
        <!-- Title column for mobile view -->
        <template #ts_last="{ row }">
          <div class="flex flex-col">
            <span class="font-medium">{{ formatDate(row.ts_last) }}</span>
          </div>
        </template>

        <template #side="{ row }">
          <span
            :class="
              row.side.toLowerCase() === 'buy' ? 'text-success' : 'text-error'
            "
          >
            {{ row.side.toUpperCase() }}
          </span>
        </template>
        <template #filled_qty="{ row }">
          {{ formatQty(row.filled_qty, row.instrument_id) }}
        </template>
        <template #avg_px="{ row }"> ${{ formatPrice(row.avg_px) }} </template>
        <template #instrument_id="{ row }">
          {{ row.instrument_id }}
        </template>
      </ResponsiveTable>

      <!-- Pagination -->
      <div
        v-if="totalPages > 1"
        class="mt-4 flex justify-center"
      >
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
          <button
            @click="currentPage > 1 && fetchOrders(currentPage - 1)"
            :disabled="currentPage === 1"
            class="pagination-button"
            :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }"
          >
            Previous
          </button>
          <button
            @click="currentPage < totalPages && fetchOrders(currentPage + 1)"
            :disabled="currentPage >= totalPages"
            class="pagination-button"
            :class="{
              'opacity-50 cursor-not-allowed': currentPage >= totalPages,
            }"
          >
            Next
          </button>
        </nav>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import ResponsiveTable from "@/components/ResponsiveTable.vue";
import {
  formatDate,
  formatLargeNumber,
  getQuotePrecision,
} from "@/utils/formatting";

export default {
  name: "BacktestOrderFillsTab",
  components: {
    ResponsiveTable,
  },
  setup() {
    const route = useRoute();
    const orders = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const isLoading = ref(true);
    const error = ref(null);

    const tableColumns = [
      { key: "ts_last", header: "Date", title: true },
      { key: "side", header: "Side" },
      { key: "filled_qty", header: "Filled Qty" },
      { key: "avg_px", header: "Price" },
      { key: "instrument_id", header: "Instrument" },
    ];

    // Format price with 2 decimal places and add $ symbol
    const formatPrice = (value) => {
      return formatLargeNumber(value, 2);
    };

    // Format quantity based on the instrument
    const formatQty = (value, instrument) => {
      // For inverse perpetuals (e.g. BTCUSD), quantity is in USD
      if (instrument?.endsWith("USD_PERPETUAL")) {
        return formatLargeNumber(value, 2);
      }
      // For other instruments, use quote currency precision
      const precision = getQuotePrecision(instrument);
      return formatLargeNumber(value, precision);
    };

    const fetchOrders = async (page) => {
      isLoading.value = true;
      error.value = null;
      try {
        const response = await axios.get(
          `/api/backtests/${route.params.id}/orders/?page=${page}`
        );

        if (response.data.results) {
          orders.value = response.data.results;
        } else {
          orders.value = response.data;
        }

        if (response.data.count !== undefined) {
          totalPages.value = Math.ceil(
            response.data.count / (response.data.page_size || 20)
          );
        } else {
          totalPages.value = 1;
        }
        currentPage.value = page;
      } catch (err) {
        console.error("Error fetching orders:", err);
        error.value = "Failed to fetch orders. Please try again later.";
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchOrders(1);
    });

    return {
      orders,
      currentPage,
      totalPages,
      isLoading,
      error,
      tableColumns,
      fetchOrders,
      formatDate,
      formatQty,
      formatPrice,
    };
  },
};
</script>

<style scoped>
.text-success {
  @apply text-green-500;
}
.text-error {
  @apply text-red-500;
}
.pagination-button {
  @apply relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50;
}
</style>
