<template>
  <div class="table-wrapper">
    <div
      class="table-container"
      :class="{
        'h-[calc(100vh-12rem)]': scrollable,
        'overflow-visible': !scrollable,
      }"
    >
      <div
        class="scroll-container"
        :class="{ 'overflow-visible': !scrollable }"
      >
        <!-- Desktop table view -->
        <table class="responsive-table table w-full hidden md:table">
          <thead class="sticky top-0">
            <tr>
              <th
                v-for="column in columns"
                :key="column.key"
                class="cursor-pointer select-none bg-base-300 sticky top-0 px-4 py-3 text-left"
                :class="{ 'cursor-default': column.sortable === false }"
                @click="column.sortable !== false && sortBy(column.key)"
              >
                <div class="flex items-center gap-2">
                  {{ column.header }}
                  <span
                    class="sort-indicator"
                    v-if="sortKey === column.key"
                  >
                    {{ sortAscending ? "↑" : "↓" }}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="overflow-y-auto">
            <template v-if="groupedData">
              <template
                v-for="(group, groupName) in groupedData"
                :key="groupName"
              >
                <tr
                  v-if="groupName"
                  class="group-header"
                >
                  <td
                    :colspan="columns.length"
                    class="text-xs text-gray-500 py-1 px-4 pt-6"
                  >
                    {{ groupName }}
                  </td>
                </tr>
                <tr
                  v-for="(row, rowIndex) in group"
                  :key="rowIndex"
                  class="hover"
                >
                  <td
                    v-for="column in columns"
                    :key="column.key"
                    :data-key="column.key"
                  >
                    <span class="td-label">{{ column.header }}:</span>
                    <span class="td-content">
                      <slot
                        :name="column.key.toLowerCase()"
                        :row="row"
                        :index="rowIndex"
                      >
                        {{ getValue(row, column, rowIndex) }}
                      </slot>
                    </span>
                  </td>
                  <td
                    v-if="
                      $slots.actions &&
                      !columns.find((c) => c.key.toLowerCase() === 'actions')
                    "
                    class="text-right"
                  >
                    <slot
                      name="actions"
                      :row="row"
                      :index="rowIndex"
                    ></slot>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr
                v-for="(row, rowIndex) in sortedData"
                :key="rowIndex"
                class="hover"
              >
                <td
                  v-for="column in columns"
                  :key="column.key"
                  :data-key="column.key"
                >
                  <span class="td-label">{{ column.header }}:</span>
                  <span class="td-content">
                    <slot
                      :name="column.key.toLowerCase()"
                      :row="row"
                      :index="rowIndex"
                    >
                      {{ getValue(row, column, rowIndex) }}
                    </slot>
                  </span>
                </td>
                <td
                  v-if="
                    $slots.actions &&
                    !columns.find((c) => c.key.toLowerCase() === 'actions')
                  "
                  class="text-right relative"
                >
                  <div class="dropdown-wrapper">
                    <slot
                      name="actions"
                      :row="row"
                      :index="rowIndex"
                    ></slot>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <!-- Mobile card view -->
        <div class="md:hidden space-y-4 p-2">
          <div
            v-for="(item, index) in groupedData
              ? Object.values(groupedData).flat()
              : sortedData"
            :key="index"
            class="bg-base-200 rounded-lg shadow relative p-4"
          >
            <!-- Title Section -->
            <div
              class="flex justify-between items-center mb-4 border-b border-base-300 pb-3"
            >
              <div class="flex items-center gap-3">
                <slot
                  name="status"
                  :row="item"
                  :index="index"
                  class="mr-2 flex-shrink-0"
                ></slot>
                <slot
                  :name="titleColumn.key.toLowerCase()"
                  :row="item"
                  :index="index"
                >
                  <span class="font-medium text-lg">
                    {{ getValue(item, titleColumn, index) }}
                  </span>
                </slot>
              </div>
              <slot
                name="mobile-actions"
                :row="item"
                :index="index"
              ></slot>
            </div>

            <!-- Primary fields -->
            <div class="grid grid-cols-2 gap-x-6 gap-y-4 mb-4">
              <template
                v-for="column in primaryColumns"
                :key="column.key"
              >
                <div
                  v-if="
                    !column.title &&
                    !['status', 'actions'].includes(column.key.toLowerCase())
                  "
                  class="flex flex-col space-y-2"
                >
                  <span class="text-xs text-gray-500 font-medium">{{
                    column.header
                  }}</span>
                  <span class="text-sm font-medium">
                    <slot
                      :name="column.key.toLowerCase()"
                      :row="item"
                      :index="index"
                    >
                      {{ getValue(item, column, index) }}
                    </slot>
                  </span>
                </div>
              </template>
            </div>

            <!-- Expandable secondary fields -->
            <div
              v-if="expandedItems.includes(index)"
              class="mt-4"
            >
              <div class="grid grid-cols-2 gap-4">
                <template
                  v-for="column in secondaryColumns"
                  :key="column.key"
                >
                  <div
                    v-if="
                      !column.title &&
                      !['status', 'actions'].includes(column.key.toLowerCase())
                    "
                    class="flex flex-col space-y-1"
                  >
                    <span class="text-xs text-gray-500">{{
                      column.header
                    }}</span>
                    <span class="truncate text-sm">
                      <slot
                        :name="column.key.toLowerCase()"
                        :row="item"
                        :index="index"
                      >
                        {{ getValue(item, column, index) }}
                      </slot>
                    </span>
                  </div>
                </template>
              </div>
            </div>

            <!-- Expand/collapse button -->
            <div
              v-if="secondaryColumns.length > 0"
              class="flex justify-center mt-4"
            >
              <button
                class="text-xs text-primary flex items-center hover:bg-base-300 px-3 py-1 rounded-lg transition-colors"
                @click="toggleExpand(index)"
              >
                {{ expandedItems.includes(index) ? "Show Less" : "Show More" }}
                <svg
                  class="w-3 h-3 ml-1"
                  :class="{
                    'transform rotate-180': expandedItems.includes(index),
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsiveTable",
  props: {
    columns: {
      type: Array,
      required: true,
      validator: (cols) => cols.every((c) => typeof c.primary === "boolean"),
    },
    data: {
      type: Array,
      required: true,
    },
    defaultSortKey: {
      type: String,
      default: null,
    },
    groupBy: {
      type: String,
      default: null,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortKey: this.defaultSortKey,
      sortAscending: true,
      expandedItems: [],
    };
  },
  computed: {
    primaryColumns() {
      const primary = this.columns.filter((c) => c.primary && !c.title);
      // If no primary columns are specified, return all non-title columns
      return primary.length > 0
        ? primary
        : this.columns.filter((c) => !c.title);
    },
    secondaryColumns() {
      // Only return secondary columns if there are primary columns specified
      const hasPrimaryColumns = this.columns.some((c) => c.primary);
      return hasPrimaryColumns ? this.columns.filter((c) => !c.primary) : [];
    },
    titleColumn() {
      return this.columns.find((c) => c.title) || this.columns[0];
    },
    sortedData() {
      if (!this.sortKey) return this.data;

      const currentColumn = this.columns.find(
        (col) => col.key === this.sortKey
      );

      return [...this.data].sort((a, b) => {
        if (currentColumn.sortFn) {
          const comparison = currentColumn.sortFn(a, b);
          return this.sortAscending ? comparison : -comparison;
        }

        const aVal = a[this.sortKey];
        const bVal = b[this.sortKey];

        if (aVal === bVal) return 0;
        const comparison = aVal > bVal ? 1 : -1;
        return this.sortAscending ? comparison : -comparison;
      });
    },
    groupedData() {
      if (this.sortKey || !this.groupBy) return null;

      const groups = {};
      const sortedData = [...this.data];
      console.log("Grouping data by:", this.groupBy);
      console.log("First item:", sortedData[0]);

      sortedData.forEach((item) => {
        const groupValue = this.getGroupValue(item, this.groupBy);
        console.log("Group value for item:", groupValue);

        if (groupValue) {
          const displayName = item.account_name || `Account ${groupValue}`;
          if (!groups[displayName]) {
            groups[displayName] = [];
          }
          groups[displayName].push(item);
        } else {
          if (!groups[""]) {
            groups[""] = [];
          }
          groups[""].push(item);
        }
      });

      console.log("Grouped data:", Object.keys(groups));
      return groups;
    },
  },
  methods: {
    getValue(row, column, index) {
      if (column.value) {
        return column.value(row, index);
      }
      return row[column.key];
    },
    getGroupValue(item, path) {
      const value = path.split(".").reduce((obj, key) => obj && obj[key], item);
      return value || null;
    },
    sortBy(key) {
      if (this.sortKey === key) {
        if (!this.sortAscending) {
          this.sortKey = null;
          this.sortAscending = true;
        } else {
          this.sortAscending = false;
        }
      } else {
        this.sortKey = key;
        this.sortAscending = true;
      }
    },
    toggleExpand(index) {
      const idx = this.expandedItems.indexOf(index);
      if (idx > -1) {
        this.expandedItems.splice(idx, 1);
      } else {
        this.expandedItems.push(index);
      }
    },
    positionDropdown(event, dropdownEl) {
      const triggerRect = event.currentTarget.getBoundingClientRect();
      const dropdownRect = dropdownEl.getBoundingClientRect();

      // Always position to the left
      const left = triggerRect.left - dropdownRect.width - 8; // 8px gap

      dropdownEl.style.position = "fixed";
      dropdownEl.style.top = `${triggerRect.top}px`;
      dropdownEl.style.left = `${left}px`;
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  position: relative;
  height: 100%;
  overflow: visible;
}

.table-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.scroll-container {
  position: relative;
}

.scroll-container:not(.overflow-visible) {
  overflow-y: auto;
  height: 100%;
}

.responsive-table {
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.responsive-table td {
  padding: 1rem 1rem;
  vertical-align: middle;
}

.responsive-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: hsl(var(--b3));
}

.responsive-table th {
  padding: 1rem 1rem;
  font-weight: 500;
  text-align: left;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
}

.responsive-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.responsive-table .td-label {
  display: none;
}

.responsive-table td[data-key="actions"] {
  text-align: right;
  position: relative;
  overflow: visible;
}

.responsive-table td[data-key="actions"] .td-label {
  display: none;
}

.responsive-table th:hover {
  background-color: hsl(var(--b2));
}

.sort-indicator {
  display: inline-block;
  font-size: 0.8em;
  opacity: 0.7;
}

.group-header td {
  border-bottom: none;
  padding-top: 1rem;
}

.group-header {
  border: none;
}

.group-header + tr {
  border-top: none;
}

.responsive-table tr {
  border-top: 1px solid hsl(var(--b3) / 0.2);
}

.responsive-table tr:first-child {
  border-top: none;
}

@media screen and (max-width: 600px) {
  .responsive-table {
    border: 0;
  }

  .responsive-table caption {
    font-size: 1.3em;
  }

  .responsive-table thead {
    position: static;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }

  .responsive-table tr {
    padding-top: 0.75em;
  }

  .responsive-table td {
    display: block;
    text-align: left;
    padding: 0.75rem 1rem;
    position: relative;
    border-bottom: 1px solid hsl(var(--b3) / 0.1);
  }

  .responsive-table .td-label {
    display: block;
    margin-bottom: 0.25rem;
    font-weight: 500;
    color: hsl(var(--bc) / 0.7);
    position: static;
    width: 100%;
  }

  .responsive-table td:last-child {
    border-bottom: none;
  }

  .responsive-table .group-header td {
    text-align: left;
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
    font-style: italic;
    color: hsl(var(--bc) / 0.5);
  }
}

.dropdown-wrapper {
  position: static; /* Let the dropdown position itself */
}

:deep(.dropdown-content) {
  position: fixed;
  z-index: 9999;
}
</style>
