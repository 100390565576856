import api from "@/api/axios";

class WalletService {
  // Make these methods instance methods
  async getWalletBalance(apiKeyId, symbol) {
    if (!apiKeyId || !symbol) return null;

    const walletType = this.getWalletType(symbol);

    try {
      const response = await api.get("/api/bots/wallet_balance/", {
        params: { api_key_id: apiKeyId, wallet_type: walletType },
      });

      const currency = this.getRelevantCurrency(symbol);
      const balance = response.data[currency] || 0;

      return {
        [currency]: balance,
        currency: currency,
        walletType: walletType,
      };
    } catch (error) {
      console.error(
        "Error fetching wallet balance:",
        error.response?.data || error
      );
      throw error;
    }
  }

  async getOpenOrdersCount(apiKeyId) {
    if (!apiKeyId) return 0;

    const response = await api.get(
      `/api/api-keys/${apiKeyId}/open_orders_count/`
    );
    return response.data.count;
  }

  getWalletType(symbol) {
    return symbol?.endsWith("USD")
      ? "inverse"
      : "spot";
  }

  getRelevantCurrency(symbol) {
    if (!symbol) return "";
    const parts = symbol.includes("/") ? symbol.split("/") : symbol.split("-");

    if (symbol.endsWith("USD")) {
      return parts[0] || "";
    }

    return parts[1] || "";
  }
}

// Export the instance
export default new WalletService();
