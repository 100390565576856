import api from "@/api/axios";

// Fallback symbol pairs if backend is unavailable
const FALLBACK_SYMBOL_PAIRS = {
  Spot: {
    "BTC-USDT": {
      base: "BTC",
      quote: "USDT",
      settle: "USDT",
    },
    "BTC-USDC": {
      base: "BTC",
      quote: "USDC",
      settle: "USDC",
    },
    "ETH-USDT": {
      base: "ETH",
      quote: "USDT",
      settle: "USDT",
    },
    "SOL-USDT": {
      base: "SOL",
      quote: "USDT",
      settle: "USDT",
    },
    "XRP-USDT": {
      base: "XRP",
      quote: "USDT",
      settle: "USDT",
    },
    "TRUMP-USDT": {
      base: "TRUMP",
      quote: "USDT",
      settle: "USDT",
    },
    "PAXG-USDT": {
      base: "PAXG",
      quote: "USDT",
      settle: "USDT",
    },
  },
  Inverse: {
    "BTC-USD": {
      base: "BTC",
      quote: "USD",
      settle: "BTC",
    },
    "ETH-USD": {
      base: "ETH",
      quote: "USD",
      settle: "ETH",
    },
    "SOL-USD": {
      base: "SOL",
      quote: "USD",
      settle: "SOL",
    },
  },
};

export default {
  namespaced: true,

  state: {
    symbolPairs: {}, // Will store category:symbols pairs dynamically
    lastFetchTime: 0,
    MAX_RETRIES: 3,
    RETRY_DELAY: 2000,
    isLoading: false,
    error: null,
    isInitialized: false,
  },

  getters: {
    getSymbolPairs: (state) => state.symbolPairs,
    getSymbolsByCategory: (state) => (category) => {
      return state.symbolPairs[category] || {};
    },
    getSymbolInfo: (state) => (symbol) => {
      for (const category in state.symbolPairs) {
        if (state.symbolPairs[category][symbol]) {
          return state.symbolPairs[category][symbol];
        }
      }
      return null;
    },
    hasError: (state) => state.error !== null,
    isLoading: (state) => state.isLoading,
  },

  mutations: {
    SET_SYMBOL_PAIRS(state, symbolPairs) {
      if (!symbolPairs) {
        console.error("Received null or undefined symbol pairs");
        return;
      }
      state.symbolPairs = symbolPairs;
      state.lastFetchTime = Date.now();
      state.error = null;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_ERROR(state, error) {
      state.error = error;
      console.error("Symbols module error:", error);
    },
    SET_INITIALIZED(state, value) {
      state.isInitialized = value;
    },
    RESET_STATE(state) {
      state.symbolPairs = {};
      state.lastFetchTime = 0;
      state.error = null;
      state.isLoading = false;
      state.isInitialized = false;
    },
  },

  actions: {
    async initializeSymbols({ dispatch, commit, state }) {
      if (state.isInitialized) {
        return;
      }

      commit("RESET_STATE");

      try {
        await dispatch("fetchSymbolPairs");
        commit("SET_INITIALIZED", true);
      } catch (error) {
        console.error("Failed to initialize symbols module:", error);
        commit("SET_ERROR", "Failed to initialize symbol data");
      }
    },

    async fetchWithRetry({ dispatch, state }, attempt = 1) {
      try {
        const response = await api.get("/api/bots/symbol_pairs/");

        if (!response.data) {
          throw new Error("Empty response from API");
        }

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        const hasValidCategory = Object.keys(response.data).length > 0;
        if (!hasValidCategory) {
          throw new Error("No valid categories found in response");
        }

        return response.data;
      } catch (error) {
        console.error(`Attempt ${attempt} failed:`, error.message);
        if (attempt < state.MAX_RETRIES) {
          await new Promise((resolve) =>
            setTimeout(resolve, state.RETRY_DELAY)
          );
          return dispatch("fetchWithRetry", attempt + 1);
        }
        throw error;
      }
    },

    async fetchSymbolPairs({ commit, dispatch }) {
      commit("SET_LOADING", true);
      commit("SET_ERROR", null);

      try {
        console.log("Fetching new symbol pairs from API...");
        const data = await dispatch("fetchWithRetry");
        console.log("Received symbol pairs from API:", data);
        commit("SET_SYMBOL_PAIRS", data);
        return data;
      } catch (error) {
        console.warn(
          "Failed to fetch from backend, using fallback symbol pairs:",
          error
        );
        commit("SET_SYMBOL_PAIRS", FALLBACK_SYMBOL_PAIRS);
        commit(
          "SET_ERROR",
          "Using fallback symbol pairs - backend unavailable"
        );
        return FALLBACK_SYMBOL_PAIRS;
      } finally {
        commit("SET_LOADING", false);
      }
    },

    cleanup({ commit }) {
      commit("RESET_STATE");
    },
  },
};
