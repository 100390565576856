import api from "@/api/axios";
import store from "@/store";

// Add caching
const specialFieldCache = new Map();
const requestInProgress = new Map();

// Add cache clearing helper
export const clearSpecialFieldCaches = () => {
  specialFieldCache.clear();
  requestInProgress.clear();
};

export const specialFields = {
  starting_price: {
    name: "starting_price",
    type: "usdt",
    default_value: "90000",
    description:
      "The highest price in the grid. For new bots, this is the current market price. For existing bots, this is the highest active grid level price.",
    getValue: async (botId = null, symbol = null) => {
      console.log("getValue called with:", { botId, symbol });

      const cacheKey = `starting_price_${botId}_${symbol}`;

      // Check if request is in progress
      const inProgressPromise = requestInProgress.get(cacheKey);
      if (inProgressPromise) {
        return inProgressPromise;
      }

      // Check cache
      const cached = specialFieldCache.get(cacheKey);
      if (cached) {
        return cached;
      }

      if (botId) {
        try {
          const processedPromise = (async () => {
            const response = await api.get(`/api/bots/${botId}/grid_levels/`);
            if (!response?.data?.grid_levels) {
              return specialFields.starting_price.default_value;
            }

            const activeGridLevels = response.data.grid_levels.filter(
              (level) => level.is_active === true  // Explicitly check for true
            );

            if (!activeGridLevels.length) {
              return specialFields.starting_price.default_value;
            }

            const prices = activeGridLevels.map((level) => Number(level.price));
            const highestPrice = Math.max(...prices);
            console.log("Found highest grid price:", highestPrice);
            return highestPrice.toString();
          })();

          requestInProgress.set(cacheKey, processedPromise);
          const result = await processedPromise;

          // Only cache non-default values
          if (result !== specialFields.starting_price.default_value) {
            specialFieldCache.set(cacheKey, result);
          }
          return result;
        } catch (error) {
          console.error("Error fetching grid levels:", error);
          return specialFields.starting_price.default_value;
        } finally {
          requestInProgress.delete(cacheKey);
        }
      }

      if (symbol) {
        // Extract the base currency from the symbol (e.g., "BTC" from "BTC/USDT" or "BTCUSDT")
        const baseCurrency = symbol.replace(/[/-].*$/, "").replace("USDT", "");
        console.log("Extracted base currency:", baseCurrency);

        // Make sure the store is initialized
        if (!store.state.price.isInitialized) {
          console.log("Initializing price store...");
          await store.dispatch("price/initializePrice");
        }

        // Try direct state access first
        const stateKey = `${baseCurrency.toLowerCase()}Price`;
        const priceFromState = store.state.price[stateKey];
        console.log("Price from direct state access:", {
          stateKey,
          priceFromState,
        });

        if (priceFromState !== null && priceFromState !== undefined) {
          console.log("Using price from state:", priceFromState);
          return priceFromState.toString();
        }

        // If direct state access fails, try the getter
        const priceFromGetter = store.getters["price/getPrice"](
          baseCurrency.toLowerCase()
        );
        console.log("Price from getter:", priceFromGetter);

        if (priceFromGetter !== null && priceFromGetter !== undefined) {
          console.log("Using price from getter:", priceFromGetter);
          return priceFromGetter.toString();
        }
      }

      console.log(
        "Falling back to default value:",
        specialFields.starting_price.default_value
      );
      return specialFields.starting_price.default_value;
    },
  },
};

export const getSpecialFields = () => {
  return Object.values(specialFields).map((field) => ({
    name: field.name,
    type: field.type,
    default_value: field.default_value,
    description: field.description,
  }));
};

export const getSpecialFieldValue = async (
  fieldName,
  botId = null,
  symbol = null,
  config = null
) => {
  const field = specialFields[fieldName];
  if (!field) return null;

  const value = await field.getValue(botId, symbol, config);
  return value;
};
