<template>
  <div>
    <h2 class="text-2xl font-semibold mb-4">Daily Returns</h2>

    <!-- Loading and Error States -->
    <div
      v-if="isLoading"
      class="text-center py-4"
    >
      Loading...
    </div>
    <div
      v-else-if="error"
      class="text-red-500 py-4"
    >
      {{ error }}
    </div>
    <template v-else>
      <ResponsiveTable
        :columns="tableColumns"
        :data="dailyReturns"
      >
        <!-- Title column for mobile view -->
        <template #date="{ row }">
          <div class="flex flex-col">
            <span class="font-medium">{{ formatDate(row.date) }}</span>
          </div>
        </template>

        <template #returns="{ row }">
          <span :class="getReturnClass(row.returns)">
            {{ formatPercent(row.returns) }}
          </span>
        </template>
        <template #cumulative_return="{ row }">
          <span :class="getReturnClass(row.cumulative_return)">
            {{ formatPercent(row.cumulative_return) }}
          </span>
        </template>
        <template #amount="{ row }">
          {{ formatCryptoAmount(row.amount) }} BTC
        </template>
      </ResponsiveTable>

      <!-- Pagination -->
      <div
        v-if="totalPages > 1"
        class="mt-4 flex justify-center"
      >
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
          <button
            @click="currentPage > 1 && fetchDailyReturns(currentPage - 1)"
            :disabled="currentPage === 1"
            class="pagination-button"
            :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }"
          >
            Previous
          </button>
          <button
            @click="
              currentPage < totalPages && fetchDailyReturns(currentPage + 1)
            "
            :disabled="currentPage >= totalPages"
            class="pagination-button"
            :class="{
              'opacity-50 cursor-not-allowed': currentPage >= totalPages,
            }"
          >
            Next
          </button>
        </nav>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import ResponsiveTable from "@/components/ResponsiveTable.vue";
import {
  formatDate,
  formatPercent,
  formatCryptoAmount,
} from "@/utils/formatting";

export default {
  name: "BacktestDailyReturnsTab",
  components: {
    ResponsiveTable,
  },
  setup() {
    const route = useRoute();
    const dailyReturns = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const isLoading = ref(true);
    const error = ref(null);

    const tableColumns = [
      { key: "date", header: "Date", title: true },
      { key: "returns", header: "Daily Return" },
      { key: "cumulative_return", header: "Cumulative Return" },
      { key: "amount", header: "Amount" },
    ];

    const fetchDailyReturns = async (page) => {
      isLoading.value = true;
      error.value = null;
      try {
        console.log(
          `Fetching daily returns for backtest ${route.params.id}, page ${page}`
        );
        const response = await axios.get(
          `/api/backtests/${route.params.id}/daily-returns/?page=${page}`
        );
        console.log("Raw API response:", response.data);
        console.log("Response type:", typeof response.data);
        console.log("Response length:", response.data.length);

        // Calculate cumulative returns
        let cumulativeReturn = 1;
        dailyReturns.value = response.data.map((item) => {
          console.log("Processing return item:", item);
          console.log("Return value:", item.returns);
          console.log("Return value type:", typeof item.returns);
          const returnValue = Number(item.returns);
          console.log("Parsed return value:", returnValue);
          cumulativeReturn *= 1 + returnValue;
          console.log("Current cumulative return:", cumulativeReturn);
          const processedItem = {
            ...item,
            cumulative_return: cumulativeReturn - 1, // Convert to percentage format
          };
          console.log("Processed return item:", processedItem);
          return processedItem;
        });
        console.log("Final processed daily returns:", dailyReturns.value);
        console.log("Final daily returns length:", dailyReturns.value.length);

        // Since we don't have pagination info in the response, we'll set totalPages to 1
        totalPages.value = 1;
        currentPage.value = page;
      } catch (err) {
        console.error("Error fetching daily returns:", err);
        console.error("Error details:", {
          message: err.message,
          response: err.response?.data,
          status: err.response?.status,
        });
        error.value = "Failed to fetch daily returns. Please try again later.";
      } finally {
        isLoading.value = false;
      }
    };

    const getReturnClass = (value) => {
      if (!value && value !== 0) return "text-gray-900";
      if (value > 0) return "text-success";
      if (value < 0) return "text-error";
      return "text-gray-900";
    };

    onMounted(() => {
      fetchDailyReturns(1);
    });

    return {
      dailyReturns,
      currentPage,
      totalPages,
      isLoading,
      error,
      tableColumns,
      fetchDailyReturns,
      formatDate,
      formatPercent,
      formatCryptoAmount,
      getReturnClass,
    };
  },
};
</script>

<style scoped>
.text-success {
  @apply text-green-500;
}
.text-error {
  @apply text-red-500;
}
.pagination-button {
  @apply relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50;
}
</style>
