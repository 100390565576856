<template>
  <div
    v-if="show"
    class="modal modal-open top-anchored-modal"
  >
    <div
      class="modal-box relative"
      style="max-height: calc(100vh - 5rem); overflow-y: auto"
    >
      <button
        class="btn btn-sm btn-circle absolute right-2 top-2"
        @click="$emit('close')"
      >
        ✕
      </button>
      <h3 class="font-bold text-lg mb-4">
        {{ isEditing ? "Edit Bot" : "Create New Bot" }}
      </h3>
      <div
        v-if="isEditing"
        class="text-sm text-base-content/70 mb-4"
      >
        {{ form.account_name || "No account" }} •
        {{ form.api_key_description || "No description" }}
      </div>

      <!-- Tabs -->
      <div class="mb-4">
        <div class="tabs tabs-boxed">
          <a
            v-for="tab in tabs"
            :key="tab"
            class="tab flex-1"
            :class="{ 'tab-active': activeTab === tab }"
            @click="activeTab = tab"
          >
            {{ tab }}
          </a>
        </div>
      </div>

      <form
        class="space-y-4"
        @submit.prevent="submitForm"
      >
        <!-- Tab Content -->
        <!-- Overview Tab -->
        <div v-show="activeTab === 'Overview'">
          <div class="grid grid-cols-1 gap-4">
            <!-- Modified to use a 2-column grid for Name and Trading Pair -->
            <div class="grid grid-cols-2 gap-4">
              <div class="form-control">
                <label class="label">
                  <span class="label-text">Name</span>
                </label>
                <input
                  v-model="form.name"
                  type="text"
                  placeholder="Bot Name"
                  class="input input-bordered"
                  required
                />
              </div>

              <div class="form-control">
                <label class="label">
                  <span class="label-text">Trading Pair</span>
                </label>
                <select
                  v-model="form.symbol"
                  class="select select-bordered"
                  required
                  :disabled="
                    !symbolPairs || Object.keys(symbolPairs).length === 0
                  "
                >
                  <option
                    disabled
                    value=""
                  >
                    {{
                      !symbolPairs || Object.keys(symbolPairs).length === 0
                        ? "Loading pairs..."
                        : "Select a trading pair"
                    }}
                  </option>
                  <optgroup
                    v-for="(pairs, category) in symbolPairs"
                    :key="category"
                    :label="category"
                  >
                    <option
                      v-for="symbol in Object.keys(pairs)"
                      :key="symbol"
                      :value="symbol"
                    >
                      {{ symbol }}
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>

            <div
              v-if="!isEditing"
              class="form-control"
            >
              <label class="label">
                <span class="label-text">Account</span>
              </label>
              <select
                v-model="form.account_id"
                class="select select-bordered"
                required
                @change="handleAccountChange"
              >
                <option
                  disabled
                  value=""
                >
                  {{
                    accounts.length === 0
                      ? "No accounts available"
                      : "Select an account"
                  }}
                </option>
                <option
                  v-for="account in accounts"
                  :key="account.id"
                  :value="account.id"
                >
                  {{ account.name }}
                </option>
              </select>
            </div>

            <div
              v-if="!isEditing"
              class="form-control"
            >
              <label class="label">
                <span class="label-text">API Key</span>
              </label>
              <select
                v-model="form.api_key_id"
                class="select select-bordered"
                required
              >
                <option
                  disabled
                  value=""
                >
                  {{
                    availableApiKeys.length === 0
                      ? "No available API keys"
                      : "Select an API key"
                  }}
                </option>
                <option
                  v-for="apiKey in availableApiKeys"
                  :key="apiKey.id"
                  :value="apiKey.id"
                >
                  {{ apiKey.description || apiKey.api_key }}
                </option>
              </select>
              <div
                v-if="availableApiKeys.length === 0 && !botToEdit"
                class="text-sm text-error mt-2"
              >
                All API keys currently have active bots. Please create a new API
                key or stop an existing bot to create a new one.
              </div>
            </div>

            <!-- Leverage slider - moved to Overview tab in mobile view -->
            <div
              class="form-control"
              v-if="
                form.account_id && form.api_key_id && form.api_key_id !== ''
              "
            >
              <label class="label">
                <span class="label-text">
                  Leverage
                  <div
                    class="tooltip tooltip-right"
                    data-tip="This setting will update the leverage on your API key"
                  >
                    <i
                      class="fas fa-info-circle text-xs ml-1 text-base-content/70"
                    ></i>
                  </div>
                </span>
                <span class="label-text-alt"
                  >{{
                    typeof form.leverage === "number"
                      ? form.leverage.toFixed(1)
                      : form.leverage
                  }}x</span
                >
              </label>
              <input
                v-model.number="form.leverage"
                type="range"
                min="1"
                max="5"
                class="range range-primary"
                step="0.1"
              />
              <div class="w-full flex justify-between text-xs px-2 mt-1">
                <span>1x</span>
                <span>2x</span>
                <span>3x</span>
                <span>4x</span>
                <span>5x</span>
              </div>
              <div class="w-full flex justify-between text-xs px-2">
                <template
                  v-for="i in 41"
                  :key="i"
                >
                  <div
                    v-if="(i - 1) % 10 === 0"
                    class="h-1 w-0.5 bg-base-content/30"
                  ></div>
                </template>
              </div>
            </div>

            <!-- Add wallet balance and open orders info -->
            <div
              class="grid grid-cols-2 gap-4 text-sm text-base-content/70 mt-2"
            >
              <div
                v-if="openOrdersCount === null && availableApiKeys.length > 0"
              >
                Fetching data...
              </div>
              <div
                v-else-if="
                  openOrdersCount !== '?' && availableApiKeys.length > 0
                "
              >
                Open Orders: {{ openOrdersCount }}
              </div>
              <div v-if="walletBalance !== null">
                Wallet Balance:
                {{ relevantCurrency === "BTC" ? "₿" : "$"
                }}{{
                  walletBalance.toLocaleString(undefined, {
                    minimumFractionDigits: relevantCurrency === "BTC" ? 8 : 2,
                    maximumFractionDigits: relevantCurrency === "BTC" ? 8 : 2,
                  })
                }}
              </div>
            </div>

            <!-- Bot Summary (moved from Summary tab) -->
            <div
              v-if="currentStrategy && form.strategy_id && form.symbol"
              class="mt-4"
            >
              <BotSummary
                :strategy="currentStrategy"
                :config="form.strategy_config"
                :special-field-values="specialFieldValues"
                :symbol="form.symbol"
              />
            </div>
          </div>
        </div>

        <!-- Strategy Tab -->
        <div v-show="activeTab === 'Strategy'">
          <div class="form-control mb-4">
            <label class="label">
              <span class="label-text">Strategy</span>
            </label>
            <select
              v-model="form.strategy_id"
              class="select select-bordered w-full"
              required
              @change="loadStrategyFields"
            >
              <option
                disabled
                value=""
              >
                Select a strategy
              </option>
              <option
                v-for="strategy in strategies"
                :key="strategy.id"
                :value="strategy.id"
              >
                {{ strategy.name }}
              </option>
            </select>
            <div
              v-if="strategyError"
              class="text-sm text-error mt-2"
            >
              {{ strategyError }}
            </div>
          </div>

          <div
            v-if="strategyFields.length > 0"
            class="form-control"
          >
            <div class="grid grid-cols-2 gap-x-4 gap-y-2">
              <div
                v-for="field in strategyFields"
                :key="field.name"
              >
                <label class="label">
                  <span class="text-xs text-gray-500">{{
                    humanizeFieldName(field.name)
                  }}</span>
                </label>
                <div class="relative">
                  <template v-if="getInputType(field).type === 'dropdown'">
                    <select
                      v-model="form.strategy_config[field.name]"
                      class="select select-bordered w-full"
                      :class="[
                        getInputType(field).class,
                        { 'opacity-50': loadingSmartFields[field.name] },
                      ]"
                    >
                      <option value="">
                        Select {{ humanizeFieldName(field.name) }}
                      </option>
                      <option
                        v-for="option in getInputType(field).options"
                        :key="option"
                        :value="option"
                      >
                        {{ humanizeFieldName(option) }}
                      </option>
                    </select>
                  </template>
                  <input
                    v-else
                    v-model.number="form.strategy_config[field.name]"
                    :type="getInputType(field).type"
                    :readonly="getInputType(field).readonly"
                    :class="[
                      'input input-bordered w-full text-sm',
                      getInputType(field).class,
                      { 'opacity-50': loadingSmartFields[field.name] },
                      { 'input-error': isQuantityFieldInvalid(field.name) },
                    ]"
                    :placeholder="field.name"
                    step="any"
                    @input="validateField(field.name)"
                  />
                  <div
                    v-if="isQuantityFieldInvalid(field.name)"
                    class="text-error text-sm mt-1"
                  >
                    Minimum quantity is {{ isInverseBot ? "$" : ""
                    }}{{ minOrderQty }}
                  </div>
                  <!-- Loading spinner -->
                  <div
                    v-if="loadingSmartFields[field.name]"
                    class="absolute right-2 top-1/2 transform -translate-y-1/2"
                  >
                    <span class="loading loading-spinner loading-sm" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Error message for no available API keys -->
        <div
          v-if="!isEditing && availableApiKeys.length === 0 && form.account_id"
          class="text-sm text-error mt-4"
        >
          All API keys have active bots on this account. Please select a new
          account, create a new API key, or stop an existing bot.
        </div>

        <!-- Message to select an account first -->
        <div
          v-if="!isEditing && !form.account_id"
          class="text-sm mt-4 text-neutral-content"
        >
          Please select an account to view available API keys.
        </div>

        <div class="modal-action">
          <div
            v-if="!isEditing"
            class="flex items-center mr-auto"
          >
            <label class="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                class="checkbox checkbox-primary checkbox-sm"
                v-model="form.auto_start"
              />
              <span class="label-text">Start bot automatically</span>
            </label>
          </div>
          <button
            type="button"
            class="btn"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="isSubmitting"
            @click="submitForm"
          >
            {{
              isSubmitting
                ? isEditing
                  ? "Updating..."
                  : "Creating..."
                : isEditing
                ? "Update"
                : "Create"
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/api/axios";
import {
  getSpecialFieldValue,
  getSpecialFields,
} from "@/services/specialFieldService";
import {
  getDefaultFields,
  getDefaultFieldValue,
} from "@/services/smartDefaultService";
import walletService from "@/services/walletService";
import BotSummary from "@/components/BotSummary.vue";
import instrumentInfoService from "@/services/instrumentInfoService";
import { defaultCurrencyRounding } from "@/utils/formatting";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    BotSummary,
  },
  props: {
    show: Boolean,
    botToEdit: {
      type: Object,
      default: () => null,
    },
    accountId: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ["close", "submit"],
  setup() {
    const store = useStore();
    const symbolPairs = computed(() => store.getters["symbols/getSymbolPairs"]);

    const getSymbolInfo = (symbol) => {
      return store.getters["symbols/getSymbolInfo"](symbol);
    };

    const fetchSymbolPairs = async () => {
      try {
        if (!store.state.symbols.isInitialized) {
          await store.dispatch("symbols/initializeSymbols");
        }
      } catch (error) {
        console.error("Error fetching symbol pairs:", error);
      }
    };

    return {
      symbolPairs,
      getSymbolInfo,
      fetchSymbolPairs,
      defaultCurrencyRounding,
    };
  },
  data() {
    return {
      isSubmitting: false,
      updateTimeout: null,
      resizeTimeout: null,
      strategyError: "",
      form: {
        name: "",
        api_key_id: "",
        symbol: "",
        strategy_id: "",
        strategy_config: {},
        account_name: "",
        api_key_description: "",
        auto_start: false,
        account_id: "",
        leverage: 1,
      },
      apiKeys: [],
      accounts: [],
      strategies: [],
      strategyFields: [],
      highestGridPrice: null,
      specialFieldValues: {},
      specialFields: [],
      activeTab: "Overview",
      tabs: ["Overview", "Strategy"],
      tabRefs: [],
      openOrdersCount: null,
      walletBalance: null,
      previousConfig: null,
      defaultFields: [],
      loadingSmartFields: {},
      minOrderQty: null,
      invalidFields: new Set(),
      globalSettings: [],
      isLoadingSymbols: false,
      isLoading: {
        symbols: false,
        wallet: false,
        orders: false,
        strategies: false,
      },
      requestCache: new Map(),
      requestInProgress: new Map(),
      useMobileView: true,
    };
  },
  computed: {
    isEditing() {
      return !!this.botToEdit && !this.botToEdit.isNewBot;
    },
    currentStrategy() {
      return this.strategies.find((s) => s.id === this.form.strategy_id);
    },
    relevantCurrency() {
      if (!this.form.symbol) return "";
      return walletService.getRelevantCurrency(this.form.symbol);
    },
    isFormValid() {
      return this.invalidFields.size === 0;
    },
    availableApiKeys() {
      // Filter out API keys that have an active bot
      // Check for active, new, or paused bots to match the backend filter
      return this.apiKeys.filter((key) => {
        if (key.active_bot) {
          // If there's an active bot already using this key, it's not available
          return false;
        }

        // Check if there are any bots with status active, new or paused
        const hasActiveOrNewOrPausedBots = key.bots?.some((bot) =>
          ["active", "new", "paused"].includes(bot.status)
        );

        return !hasActiveOrNewOrPausedBots;
      });
    },
    isFormDisabled() {
      return !this.isEditing && this.availableApiKeys.length === 0;
    },
    isInverseBot() {
      return !this.form.symbol?.includes("USDT");
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.fetchSymbolPairs();
        this.initializeForm();
        if (this.botToEdit) {
          this.fetchOpenOrdersCount();
        }
        // Check modal size after it's visible and content is loaded
        this.$nextTick(() => {
          this.checkModalSize();
        });
      }
    },
    botToEdit(newVal) {
      if (newVal) {
        this.initializeForm();
        this.$nextTick(() => {
          this.checkModalSize();
        });
      }
    },
    "form.strategy_id": {
      async handler() {
        await this.loadStrategyFields();
        this.updateSpecialFieldValues();
        this.$nextTick(() => {
          this.checkModalSize();
        });
      },
    },
    strategyFields: {
      handler() {
        this.$nextTick(() => {
          this.checkModalSize();
        });
      },
      deep: true,
    },
    "form.symbol": {
      async handler() {
        this.updateSpecialFieldValues();
        if (this.form.symbol && this.form.api_key_id) {
          this.fetchWalletBalance();
          this.updateSmartFields();
          this.fetchOpenOrdersCount();
          this.fetchMinOrderQty();
        } else {
          this.walletBalance = null;
          this.openOrdersCount = null;
          this.minOrderQty = null;
        }
      },
    },
    "form.api_key_id"(newVal) {
      if (newVal) {
        // Prefetch wallet data if symbol is already selected
        if (this.form.symbol) {
          this.fetchWalletBalance();
          this.fetchOpenOrdersCount();
        }

        // Update leverage from the selected API key
        if (!this.isEditing) {
          const apiKey = this.apiKeys.find((key) => key.id === newVal);
          if (apiKey) {
            this.form.leverage = apiKey.leverage || 1;
          }
        }
      }
    },
    "form.strategy_config.n_levels": {
      handler() {
        this.updateSmartFields();
      },
      deep: true,
      immediate: true,
    },
    "form.strategy_config": {
      deep: true,
      handler() {
        this.updateSmartFields();
        this.$nextTick(() => {
          this.checkModalSize();
        });
      },
    },
  },
  mounted() {
    this.initializeForm();
    this.defaultFields = getDefaultFields();
    this.checkModalSize();
    window.addEventListener("resize", this.checkModalSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkModalSize);
  },
  methods: {
    checkModalSize() {
      // Always use the tab system
      this.useMobileView = true;
    },

    async initializeForm() {
      // Reset form state first
      this.resetState();

      // If editing or copying, populate form with bot data
      if (this.botToEdit) {
        console.log("Initializing form with bot data:", this.botToEdit);
        console.log("Bot leverage value:", this.botToEdit.leverage);

        // Check if this is a copied bot (isNewBot flag is set)
        const isCopiedBot = this.botToEdit.isNewBot === true;

        this.form = {
          name: this.botToEdit.name,
          api_key_id: isCopiedBot ? "" : this.botToEdit.api_key_id,
          symbol: this.botToEdit.symbol,
          strategy_id: this.botToEdit.strategy_id,
          strategy_config: { ...this.botToEdit.strategy_config }, // Ensure we make a deep copy
          account_name: isCopiedBot ? "" : this.botToEdit.account_name,
          api_key_description: isCopiedBot
            ? ""
            : this.botToEdit.api_key_description,
          auto_start: isCopiedBot ? false : this.botToEdit.auto_start,
          account_id: isCopiedBot ? "" : this.botToEdit.account_id || "",
          leverage: this.botToEdit.leverage || 1,
        };

        console.log("Form leverage set to:", this.form.leverage);

        // Store the original config for reference
        this.previousConfig = { ...this.botToEdit.strategy_config };
      } else if (this.accountId) {
        // If creating a new bot with a specific account ID
        this.form.account_id = this.accountId;
      }

      // Critical path operations - required for form functionality
      const criticalPromises = [
        this.fetchSymbolPairs(),
        this.fetchStrategies(),
        this.fetchSpecialFields(),
        this.fetchAccounts(),
      ];

      await Promise.all(criticalPromises).catch(console.error);

      // Fetch API keys after accounts are loaded
      if (this.form.account_id) {
        await this.fetchApiKeys();

        // Set leverage from API key after API keys are loaded
        if (this.isEditing && this.form.api_key_id) {
          const apiKey = this.apiKeys.find(
            (key) => key.id === this.form.api_key_id
          );
          if (apiKey && apiKey.leverage) {
            this.form.leverage = apiKey.leverage;
            console.log("Updated leverage from API key:", this.form.leverage);
          }
        }
      }

      // Load strategy fields after we have the strategy data
      if (this.form.strategy_id) {
        await this.loadStrategyFields();
      }

      // Non-critical operations - can load in background
      if (this.botToEdit && !this.botToEdit.isNewBot) {
        // Start these in parallel but don't await them
        if (this.form.account_id && this.form.api_key_id) {
          this.fetchOpenOrdersCount();
          this.fetchWalletBalance();
        }
        this.fetchHighestGridLevel();
        this.updateSpecialFieldValues();
      }
    },

    async fetchAccounts() {
      try {
        const cacheKey = "accounts";
        const response = await this.cachedRequest(cacheKey, () =>
          api.get("/api/accounts/")
        );
        this.accounts = response.data;

        // If editing, set the form leverage from the API key
        if (this.isEditing) {
          const apiKey = this.apiKeys.find(
            (key) => key.id === this.botToEdit.api_key_id
          );
          if (apiKey) {
            this.form.leverage = apiKey.leverage || 1;
          }
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
        this.accounts = [];
      }
    },

    handleAccountChange() {
      // Reset API key selection when account changes
      this.form.api_key_id = "";
      this.walletBalance = null;
      this.openOrdersCount = null;

      // Fetch API keys for the selected account
      if (this.form.account_id) {
        this.fetchApiKeys();
      } else {
        this.apiKeys = [];
      }
    },

    resetState() {
      this.isSubmitting = false;
      this.form = {
        name: "",
        api_key_id: "",
        symbol: "",
        strategy_id: "",
        strategy_config: {},
        account_name: "",
        api_key_description: "",
        auto_start: false,
        leverage: 1,
        account_id: "",
      };
      this.strategyFields = [];
    },

    async fetchApiKeys() {
      if (!this.form.account_id) {
        this.apiKeys = [];
        return;
      }

      try {
        const accountId = this.form.account_id;
        console.log("Fetching API keys for account:", accountId);

        const response = await api.get(`/api/api-keys/`, {
          params: {
            account_id: accountId,
          },
        });
        this.apiKeys = response.data;
        console.log("Fetched API keys:", this.apiKeys.length);
      } catch (error) {
        console.error("Error fetching API keys:", error);
        this.apiKeys = [];
      }
    },

    async fetchStrategies() {
      try {
        const cacheKey = "strategies";
        const response = await this.cachedRequest(cacheKey, () =>
          api.get("/api/strategies/")
        );
        this.strategies = response.data;
      } catch {
        // Error handled silently
      }
    },

    async fetchGlobalSettings() {
      try {
        const response = await api.get("/api/global-settings/");
        this.globalSettings = response.data;
      } catch (error) {
        console.error("Error fetching global settings:", error);
        this.globalSettings = [];
      }
    },

    getGlobalSettingValue(fieldName) {
      // First try to find a setting matching both category and symbol
      const symbolSetting = this.globalSettings.find(
        (setting) =>
          setting.key === fieldName &&
          setting.category === this.getCategory() &&
          setting.symbol === this.form.symbol
      );
      if (symbolSetting) return symbolSetting.value;

      // Then try to find a category-level setting
      const categorySetting = this.globalSettings.find(
        (setting) =>
          setting.key === fieldName &&
          setting.category === this.getCategory() &&
          !setting.symbol
      );
      if (categorySetting) return categorySetting.value;

      // Finally try to find a global setting
      const globalSetting = this.globalSettings.find(
        (setting) =>
          setting.key === fieldName && !setting.category && !setting.symbol
      );
      return globalSetting ? globalSetting.value : null;
    },

    getCategory() {
      // Determine if the symbol is Spot or Inverse based on the symbol
      if (!this.form.symbol) return null;
      return this.form.symbol.includes("USDT") ? "Spot" : "Inverse";
    },

    async loadStrategyFields() {
      console.log(
        "Loading strategy fields for strategy_id:",
        this.form.strategy_id
      );
      if (this.form.strategy_id) {
        // Clear any strategy error when a strategy is selected
        this.strategyError = "";

        try {
          const cacheKey = `strategy_${this.form.strategy_id}`;
          const response = await this.cachedRequest(cacheKey, () =>
            api.get(`/api/strategies/${this.form.strategy_id}/`)
          );

          console.log("Strategy response:", response.data);
          this.strategyFields = response.data.fields;
          console.log("Raw strategy fields:", this.strategyFields);

          // Ensure strategy_config exists
          if (!this.form.strategy_config) {
            this.form.strategy_config = {};
          }

          // Only set default values for fields that don't exist in the current config
          for (const field of this.strategyFields) {
            // Skip if the field already has a value in the config (including 0)
            if (this.form.strategy_config[field.name] !== undefined) {
              console.log(
                `Preserving existing value for ${field.name}:`,
                this.form.strategy_config[field.name]
              );
              continue;
            }

            // For new fields only, set defaults
            if (field.type === "smart") {
              try {
                const result = await getDefaultFieldValue(
                  field.name,
                  this.strategyFields,
                  this.form.strategy_config,
                  this.botToEdit
                );

                if (result && result.value !== undefined) {
                  console.log(
                    `Setting default value for ${field.name}:`,
                    result.value
                  );
                  this.form.strategy_config[field.name] = result.value;
                }
              } catch (error) {
                console.error(
                  `Error setting default value for ${field.name}:`,
                  error
                );
              }
            } else if (field.type === "dropdown") {
              const options =
                field.options?.split(",").map((opt) => opt.trim()) ||
                field.default_value?.split(",").map((opt) => opt.trim()) ||
                [];
              this.form.strategy_config[field.name] = options[0] || "";
            } else {
              // Check for global setting value first
              const globalValue = this.getGlobalSettingValue(field.name);
              this.form.strategy_config[field.name] =
                globalValue !== null ? globalValue : field.default_value;
            }
          }
        } catch (error) {
          console.error("Error loading strategy fields:", error);
        }
      }
    },

    getInputType(field) {
      if (field.type === "smart") {
        return {
          type: "number",
          readonly: false,
          class: "input-bordered",
        };
      }

      switch (field.type) {
        case "quantity":
          return {
            type: "number",
            readonly: false,
            min: this.minOrderQty || 0,
            step: "any",
          };
        case "int":
          return { type: "number", readonly: false };
        case "float":
        case "decimal":
          return { type: "number", readonly: false };
        case "dropdown":
          return {
            type: "dropdown",
            readonly: false,
            options:
              field.options?.split(",").map((opt) => opt.trim()) ||
              field.default_value?.split(",").map((opt) => opt.trim()) ||
              [],
          };
        default:
          return { type: "text", readonly: false };
      }
    },

    async submitForm() {
      console.log("Submit form called");

      if (this.isSubmitting) {
        console.log("Form is already submitting, returning");
        return; // Prevent multiple submissions
      }

      // Force validation of required fields
      const requiredFields = document.querySelectorAll(
        "select[required], input[required]"
      );
      let isValid = true;

      requiredFields.forEach((field) => {
        if (!field.value) {
          console.log("Required field empty:", field);
          isValid = false;
          field.classList.add("input-error");
        }
      });

      // Check if strategy is selected
      if (!this.form.strategy_id) {
        console.log("No strategy selected, showing error");
        this.strategyError = "Strategy is required. Please select a strategy.";
        // Switch to the Strategy tab to make the error visible
        this.$nextTick(() => {
          console.log("Switching to Strategy tab");
          this.activeTab = "Strategy";
        });
        return;
      }

      if (!isValid) {
        console.log("Form validation failed");
        return;
      }

      this.isSubmitting = true;
      try {
        // Clear any previous errors
        this.strategyError = "";

        // Validate all quantity fields
        const quantityFields = this.strategyFields.filter(
          (field) => field.type === "quantity"
        );

        quantityFields.forEach((field) => this.validateField(field.name));

        if (!this.isFormValid) {
          throw new Error("Please fix the invalid fields before submitting");
        }

        // Update API key leverage if it has changed
        const apiKeyId = this.form.api_key_id;
        const currentApiKey = this.apiKeys.find((key) => key.id === apiKeyId);

        if (currentApiKey && currentApiKey.leverage !== this.form.leverage) {
          try {
            await api.post(`/api/api-keys/${apiKeyId}/update_leverage/`, {
              leverage: this.form.leverage,
            });
            console.log(`Updated leverage to ${this.form.leverage}x`);
          } catch (error) {
            console.error("Error updating leverage:", error);
            // Continue with bot creation/update even if leverage update fails
          }
        }

        const formData = {
          name: this.form.name,
          symbol: this.form.symbol,
          strategy_id: this.form.strategy_id,
          strategy_config: this.form.strategy_config,
          account_id: this.form.account_id,
          api_key_id: this.form.api_key_id,
          auto_start: this.form.auto_start,
        };

        console.log("Submitting form data:", formData);
        await this.$emit("submit", formData);
        // Don't reset isSubmitting here as the parent component should handle closing the modal
      } catch (error) {
        console.error(error.message);
        this.isSubmitting = false;
      }
    },

    setErrors(errors = {}) {
      this.isSubmitting = false;
      // Handle specific field errors if needed
      if (errors.non_field_errors) {
        alert(errors.non_field_errors.join("\n"));
      }
    },

    humanizeFieldName(fieldName) {
      return fieldName
        .split(/[_\s]+|(?=[A-Z])/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    },

    getAccountName(accountId) {
      const account = this.accounts.find((acc) => acc.id === accountId);
      return account ? account.name : "";
    },

    getApiKeyDescription(apiKeyId) {
      const apiKey = this.apiKeys.find((key) => key.id === apiKeyId);
      if (apiKey) {
        return apiKey.description || apiKey.api_key;
      } else {
        return "Unknown API Key";
      }
    },

    async fetchHighestGridLevel() {
      if (!this.isEditing || !this.botToEdit?.id) return;

      try {
        const cacheKey = `grid_levels_${this.botToEdit.id}`;
        const gridLevels = await this.cachedRequest(cacheKey, async () => {
          const response = await api.get(
            `/api/bots/${this.botToEdit.id}/grid_levels/`
          );
          return response.data.grid_levels;
        });

        const activeGridLevels = gridLevels.filter((level) => level.is_active);
        if (activeGridLevels.length > 0) {
          const prices = activeGridLevels.map((level) => Number(level.price));
          this.highestGridPrice = Math.max(...prices);
        } else {
          this.highestGridPrice = null;
        }
      } catch (error) {
        this.highestGridPrice = null;
      }
    },

    isCurrencyField(fieldName) {
      return (
        fieldName.toLowerCase().includes("price") ||
        fieldName.toLowerCase().includes("amount") ||
        fieldName.toLowerCase().includes("range")
      );
    },

    isPercentageField(fieldName) {
      return (
        fieldName.toLowerCase().includes("percentage") ||
        fieldName.toLowerCase().includes("percent")
      );
    },

    async updateSpecialFieldValues() {
      const strategy = this.strategies.find(
        (s) => s.id === this.form.strategy_id
      );
      if (!strategy?.custom_fields) return;

      const specialFields = strategy.custom_fields.filter(
        (f) => f.type === "special"
      );

      // Clear existing values first
      this.specialFieldValues = {};

      // Use Promise.all to fetch all special field values in parallel
      const fieldPromises = specialFields.map(async (field) => {
        try {
          const value = await getSpecialFieldValue(
            field.name,
            this.isEditing ? this.botToEdit?.id : null,
            this.form.symbol,
            this.form.strategy_config
          );

          if (value) {
            this.specialFieldValues[field.name] = value;
          }
        } catch (error) {
          console.error(
            `Error fetching special field value for ${field.name}:`,
            error
          );
        }
      });

      await Promise.all(fieldPromises);
    },

    orderCustomFieldsByDependency(fields) {
      const result = [];
      const visited = new Set();
      const visiting = new Set();

      const visit = (field) => {
        if (visiting.has(field.name)) {
          return; // Skip circular dependencies
        }
        if (visited.has(field.name)) {
          return;
        }

        visiting.add(field.name);

        // Find dependencies in the formula
        const dependencies = fields.filter(
          (f) => field.formula.includes(`{${f.name}}`) && f.name !== field.name
        );

        // Visit dependencies first
        dependencies.forEach((dep) => visit(dep));

        visiting.delete(field.name);
        visited.add(field.name);
        result.push(field);
      };

      fields.forEach((field) => {
        if (!visited.has(field.name)) {
          visit(field);
        }
      });

      return result;
    },

    async fetchSpecialFields() {
      try {
        const fields = await getSpecialFields();
        this.specialFields = fields;
      } catch (error) {
        this.specialFields = [];
      }
    },

    async fetchOpenOrdersCount() {
      if (this.isLoading.orders) return; // Prevent duplicate requests
      this.isLoading.orders = true;

      try {
        const apiKeyId = this.isEditing
          ? this.botToEdit.api_key_id
          : this.form.api_key_id;
        if (!apiKeyId || !this.form.account_id) {
          this.openOrdersCount = null;
          return;
        }

        const cacheKey = `orders_count_${apiKeyId}`;
        this.openOrdersCount = await this.cachedRequest(cacheKey, () =>
          walletService.getOpenOrdersCount(apiKeyId)
        );
      } catch (error) {
        this.openOrdersCount = "?";
      } finally {
        this.isLoading.orders = false;
      }
    },

    async fetchWalletBalance() {
      if (this.isLoading.wallet) return; // Prevent duplicate requests
      this.isLoading.wallet = true;

      try {
        if (!this.form.api_key_id || !this.form.symbol) {
          this.walletBalance = null;
          return;
        }

        const walletInfo = await walletService.getWalletBalance(
          this.form.api_key_id,
          this.form.symbol
        );
        this.walletBalance = walletInfo[walletInfo.currency] || 0;
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
        this.walletBalance = null;
      } finally {
        this.isLoading.wallet = false;
      }
    },

    calculateSmartField(fieldName, formula, config) {
      try {
        // Replace field references with actual values
        let calculatedFormula = formula;
        Object.entries(config).forEach(([key, value]) => {
          calculatedFormula = calculatedFormula.replace(
            new RegExp(`\\{${key}\\}`, "g"),
            value
          );
        });

        // Evaluate the formula
        const result = new Function(`return ${calculatedFormula}`)();
        return result.toString();
      } catch (error) {
        console.error(`Error calculating ${fieldName}:`, error);
        return "0";
      }
    },

    async updateSmartFields() {
      if (this.isEditing) return;

      // Create an array of promises to handle all field updates
      const updatePromises = this.strategyFields.map(async (field) => {
        if (field.type === "smart") {
          const defaultField = this.defaultFields.find(
            (df) => df.name === field.name
          );
          if (!defaultField) return;

          try {
            // Set loading state for this field
            this.loadingSmartFields[field.name] = true;

            const result = await getDefaultFieldValue(
              field.name,
              this.form.strategy_config,
              {
                apiKeyId: this.form.api_key_id,
                symbol: this.form.symbol,
              }
            );

            if (result && result.value !== undefined) {
              this.form.strategy_config[field.name] = result.value;
            }
          } catch (error) {
            console.error(
              `Error calculating smart field ${field.name}:`,
              error
            );
          } finally {
            // Clear loading state
            this.loadingSmartFields[field.name] = false;
          }
        }
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);
    },

    async fetchMinOrderQty() {
      if (!this.form.symbol) return;
      try {
        console.log("Fetching min order qty for:", this.form.symbol);
        this.minOrderQty = await instrumentInfoService.getMinOrderQty(
          this.form.symbol,
          this.getCategory()
        );
        console.log("Fetched min order qty:", this.minOrderQty);

        // Validate all quantity fields when min order qty changes
        this.strategyFields
          .filter((field) => field.type === "quantity")
          .forEach((field) => this.validateField(field.name));
      } catch (error) {
        console.error("Error fetching minimum order quantity:", error);
        this.minOrderQty = null;
      }
    },

    isQuantityFieldInvalid(fieldName) {
      return this.invalidFields.has(fieldName);
    },

    validateField(fieldName) {
      const field = this.strategyFields.find((f) => f.name === fieldName);
      if (field?.type !== "quantity") return;

      const value = parseFloat(this.form.strategy_config[fieldName]);
      const minQty = parseFloat(this.minOrderQty);

      console.log(`Validating ${fieldName}:`, { value, minQty });

      if (!minQty || isNaN(minQty)) {
        console.warn("No minimum quantity available for validation");
        return;
      }

      if (isNaN(value) || value < minQty) {
        this.invalidFields.add(fieldName);
      } else {
        this.invalidFields.delete(fieldName);
      }
    },

    async cachedRequest(key, requestFn) {
      // Check if request is in progress
      if (this.requestInProgress.get(key)) {
        return this.requestInProgress.get(key);
      }

      // Check cache
      const cached = this.requestCache.get(key);
      if (cached) {
        return cached;
      }

      // Set request in progress
      const promise = requestFn();
      this.requestInProgress.set(key, promise);

      try {
        const result = await promise;
        // Cache the result
        this.requestCache.set(key, result);
        return result;
      } finally {
        this.requestInProgress.delete(key);
      }
    },
  },
};
</script>

<style scoped>
/* Default behavior for mobile: centered modal (default modal behavior) */
.top-anchored-modal {
  /* No custom alignment on mobile - will use modal's default centering */
}

/* Desktop behavior: top-anchored modal */
@media (min-width: 768px) {
  .top-anchored-modal {
    align-items: flex-start;
    padding-top: 8rem;
  }
}
</style>
