import api from "@/api/axios";

// Cache instrument info to avoid repeated API calls
const instrumentInfoCache = new Map();
const requestInProgress = new Map();

/**
 * Fetches instrument information from our backend
 * @param {string} symbol - Trading pair symbol (e.g., "BTC-USDT")
 * @param {string} category - Market category (e.g., "spot", "inverse")
 * @returns {Promise<Object>} Instrument information
 */
export const getInstrumentInfo = async (symbol, category = "spot") => {
  const cacheKey = `${category}-${symbol}`;
  console.log(`Fetching instrument info for ${symbol} (${category})`);

  // Check if request is in progress
  if (requestInProgress.get(cacheKey)) {
    return requestInProgress.get(cacheKey);
  }

  // Return cached data if available
  const cachedData = instrumentInfoCache.get(cacheKey);
  if (cachedData) {
    console.log("Returning cached data:", cachedData);
    return cachedData;
  }

  try {
    // Set request in progress
    const promise = api.get(`/api/market/instrument-info/`, {
      params: {
        symbol,
        category,
      },
    });
    requestInProgress.set(cacheKey, promise);

    const response = await promise;
    console.log("Raw API response:", response.data);

    // Cache the response
    instrumentInfoCache.set(cacheKey, response.data);

    return response.data;
  } catch (error) {
    console.error(`Error fetching instrument info for ${symbol}:`, error);
    throw error;
  } finally {
    requestInProgress.delete(cacheKey);
  }
};

/**
 * Gets the minimum order quantity for a symbol
 * @param {string} symbol - Trading pair symbol (e.g., "BTC-USDT")
 * @param {string} category - Market category (e.g., "spot", "inverse")
 * @returns {Promise<string>} Minimum order quantity
 */
export const getMinOrderQty = async (symbol, category = "spot") => {
  const instrumentInfo = await getInstrumentInfo(symbol, category);
  const minOrderQty = instrumentInfo?.min_order_qty;
  console.log(`Minimum order quantity for ${symbol}: ${minOrderQty}`);
  return minOrderQty;
};

/**
 * Gets the tick size (minimum price movement) for a symbol
 * @param {string} symbol - Trading pair symbol (e.g., "BTC-USDT")
 * @param {string} category - Market category (e.g., "spot", "inverse")
 * @returns {Promise<string>} Tick size
 */
export const getTickSize = async (symbol, category = "spot") => {
  const instrumentInfo = await getInstrumentInfo(symbol, category);
  return instrumentInfo?.tick_size;
};

export default {
  getInstrumentInfo,
  getMinOrderQty,
  getTickSize,
};
