<template>
  <div class="flex gap-6">
    <!-- Left Column - Charts -->
    <div class="w-3/5 space-y-6">
      <!-- All charts in a single column -->
      <div class="space-y-6">
        <!-- Cumulative Returns Chart -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">Cumulative Returns vs SPY</h3>
            <button
              class="btn btn-sm"
              @click="fitContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="chartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#FFB74D]"></div>
              <span>Benchmark</span>
            </div>
          </div>
        </div>

        <!-- Cumulative Returns (Log Scaled) -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">
              Cumulative Returns vs SPY (Log Scaled)
            </h3>
            <button
              class="btn btn-sm"
              @click="fitLogContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="logChartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#FFB74D]"></div>
              <span>Benchmark</span>
            </div>
          </div>
        </div>

        <!-- Cumulative Returns vs SPY (Volatility Matched) -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">
              Cumulative Returns (Volatility Matched)
            </h3>
            <button
              class="btn btn-sm"
              @click="fitVolContent"
            >
              Fit Content
            </button>
          </div>
          <div class="h-[300px] relative">
            <div
              class="w-full h-full bg-base-200 rounded flex items-center justify-center text-muted"
            >
              Chart Coming Soon
            </div>
          </div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#FFB74D]"></div>
              <span>Benchmark</span>
            </div>
          </div>
        </div>

        <!-- EOY Returns vs Benchmark -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <h3 class="text-lg font-semibold mb-4">EOY Returns</h3>
          <div class="h-[300px] relative">
            <canvas ref="eoyBarChart"></canvas>
          </div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#FFB74D]"></div>
              <span>Benchmark</span>
            </div>
          </div>
        </div>

        <!-- Distribution of Monthly Returns -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <h3 class="text-lg font-semibold mb-4">
            Distribution of Monthly Returns
          </h3>
          <div class="h-[300px] relative">
            <canvas ref="monthlyDistChart"></canvas>
          </div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="w-3 h-0.5 border-t-2 border-dashed border-[#FF4949]"
              ></div>
              <span>Average</span>
            </div>
          </div>
        </div>

        <!-- Daily Returns -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">Daily Returns</h3>
            <button
              class="btn btn-sm"
              @click="fitDailyContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="dailyChartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Daily Returns</span>
            </div>
          </div>
        </div>

        <!-- Rolling Beta to Benchmark -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <h3 class="text-lg font-semibold mb-4">Rolling Beta to Benchmark</h3>
          <div class="h-[300px]">
            <div
              class="w-full h-full bg-base-200 rounded flex items-center justify-center text-muted"
            >
              Chart Coming Soon
            </div>
          </div>
        </div>

        <!-- Rolling Volatility -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">Rolling Volatility (6-Months)</h3>
            <button
              class="btn btn-sm"
              @click="fitVolContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="volChartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#FFB74D]"></div>
              <span>Benchmark</span>
            </div>
          </div>
        </div>

        <!-- Rolling Sharpe -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">Rolling Sharpe (6-Months)</h3>
            <button
              class="btn btn-sm"
              @click="fitSharpeContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="sharpeChartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
          </div>
        </div>

        <!-- Rolling Sortino -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">Rolling Sortino (6-Months)</h3>
            <button
              class="btn btn-sm"
              @click="fitSortinoContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="sortinoChartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
          </div>
        </div>

        <!-- Top 5 Drawdown Periods -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">Top 5 Drawdown Periods</h3>
            <button
              class="btn btn-sm"
              @click="fitDrawdownContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="drawdownChartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Strategy</span>
            </div>
          </div>
        </div>

        <!-- Underwater Plot -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold">Underwater Plot</h3>
            <button
              class="btn btn-sm"
              @click="fitUnderwaterContent"
            >
              Fit Content
            </button>
          </div>
          <div
            class="h-[300px] relative"
            ref="underwaterChartContainer"
          ></div>
          <div class="flex justify-end gap-4 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div class="w-3 h-0.5 bg-[#2196F3]"></div>
              <span>Drawdown</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="w-3 h-0.5 border-t-2 border-dashed border-[#FF4949]"
              ></div>
              <span>Average</span>
            </div>
          </div>
        </div>

        <!-- Monthly Returns Heatmap -->
        <div class="card bg-card rounded-lg p-4 border border-base-300">
          <h3 class="text-lg font-semibold mb-3">Monthly Returns (%)</h3>
          <div class="overflow-x-auto">
            <table class="w-full text-xs border-collapse">
              <thead>
                <tr>
                  <th
                    class="text-left text-muted font-medium p-1.5 w-16 border-r border-base-300"
                  >
                    Year
                  </th>
                  <template
                    v-for="month in [
                      'JAN',
                      'FEB',
                      'MAR',
                      'APR',
                      'MAY',
                      'JUN',
                      'JUL',
                      'AUG',
                      'SEP',
                      'OCT',
                      'NOV',
                      'DEC',
                    ]"
                    :key="month"
                  >
                    <th
                      class="text-center text-muted font-medium p-1.5 w-[7.5%]"
                    >
                      {{ month }}
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-if="backtest?.chart_data?.monthly_returns?.data">
                  <tr
                    v-for="year in Object.keys(
                      backtest.chart_data.monthly_returns.data
                    )
                      .sort()
                      .reverse()"
                    :key="year"
                  >
                    <td
                      class="text-left text-muted p-1.5 border-r border-base-300 font-medium"
                    >
                      {{ year }}
                    </td>
                    <template
                      v-for="month in [
                        'JAN',
                        'FEB',
                        'MAR',
                        'APR',
                        'MAY',
                        'JUN',
                        'JUL',
                        'AUG',
                        'SEP',
                        'OCT',
                        'NOV',
                        'DEC',
                      ]"
                      :key="month"
                    >
                      <td
                        class="text-center p-1.5"
                        :style="{
                          backgroundColor: getHeatmapColor(
                            backtest.chart_data.monthly_returns.data[year][
                              month
                            ]
                          ),
                          color: getTextColor(
                            backtest.chart_data.monthly_returns.data[year][
                              month
                            ]
                          ),
                          borderRight: '1px solid rgba(0,0,0,0.05)',
                        }"
                      >
                        {{
                          formatHeatmapValue(
                            backtest.chart_data.monthly_returns.data[year][
                              month
                            ]
                          )
                        }}
                      </td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Return Quartiles -->
        <div class="card bg-card rounded-lg p-6 border border-base-300">
          <h3 class="text-lg font-semibold mb-4">Return Quartiles</h3>
          <div class="h-[300px] relative">
            <canvas ref="returnQuartilesChart"></canvas>
          </div>
          <div class="flex justify-center gap-6 mt-2 text-sm text-muted">
            <div class="flex items-center gap-1.5">
              <div
                class="w-3 h-3 rounded"
                style="background: rgba(59, 130, 246, 0.5)"
              ></div>
              <span>Daily</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="w-3 h-3 rounded"
                style="background: rgba(16, 185, 129, 0.5)"
              ></div>
              <span>Weekly</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="w-3 h-3 rounded"
                style="background: rgba(245, 158, 11, 0.5)"
              ></div>
              <span>Monthly</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="w-3 h-3 rounded"
                style="background: rgba(139, 92, 246, 0.5)"
              ></div>
              <span>Quarterly</span>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="w-3 h-3 rounded"
                style="background: rgba(236, 72, 153, 0.5)"
              ></div>
              <span>Yearly</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Column - Metrics -->
    <div
      class="w-2/5 space-y-6"
      v-if="backtest?.key_metrics"
    >
      <!-- Key Performance Metrics -->
      <div class="card bg-card rounded-lg p-6 border border-base-300">
        <h3 class="text-lg font-semibold mb-4">Key Performance Metrics</h3>
        <div class="grid grid-cols-2 gap-4">
          <!-- Header -->
          <div class="text-muted font-medium">Metric</div>
          <div class="text-muted text-right font-medium">Value</div>

          <!-- Time in Market -->
          <div class="text-muted">Time in Market</div>
          <div class="text-right">
            {{ formatPercentage(backtest.key_metrics.time_in_market) || "N/A" }}
          </div>

          <!-- Divider -->
          <div class="col-span-2 mt-4 mb-1">
            <div class="h-px bg-base-300"></div>
          </div>

          <!-- Cumulative Return -->
          <div class="text-muted">Cumulative Return</div>
          <div class="text-right">
            {{
              formatPercentage(backtest.key_metrics.cumulative_return) || "N/A"
            }}
          </div>

          <!-- CAGR -->
          <div class="text-muted">CAGR%</div>
          <div class="text-right">
            {{ formatPercentage(backtest.key_metrics.cagr) || "N/A" }}
          </div>

          <!-- Divider -->
          <div class="col-span-2 mt-4 mb-1">
            <div class="h-px bg-base-300"></div>
          </div>

          <!-- Sharpe -->
          <div class="text-muted">Sharpe</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.sharpe) || "N/A" }}
          </div>

          <!-- Sortino -->
          <div class="text-muted">Sortino</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.sortino) || "N/A" }}
          </div>

          <!-- Adjusted Sortino -->
          <div class="text-muted">Adjusted Sortino</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.adjusted_sortino) || "N/A" }}
          </div>

          <!-- Divider -->
          <div class="col-span-2 mt-4 mb-1">
            <div class="h-px bg-base-300"></div>
          </div>

          <!-- Max Drawdown -->
          <div class="text-muted">Max Drawdown</div>
          <div class="text-right">
            {{ formatPercentage(backtest.key_metrics.max_drawdown) || "N/A" }}
          </div>

          <!-- Longest DD Days -->
          <div class="text-muted">Longest DD Days</div>
          <div class="text-right">
            {{ backtest.key_metrics.longest_dd_days || "N/A" }}
          </div>

          <!-- Volatility -->
          <div class="text-muted">Volatility</div>
          <div class="text-right">
            {{ formatPercentage(backtest.key_metrics.volatility) || "N/A" }}
          </div>

          <!-- Calmar -->
          <div class="text-muted">Calmar</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.calmar) || "N/A" }}
          </div>

          <!-- Divider -->
          <div class="col-span-2 mt-4 mb-1">
            <div class="h-px bg-base-300"></div>
          </div>

          <!-- Expected Returns -->
          <div class="text-muted">Expected Daily</div>
          <div class="text-right">
            {{ formatPercentage(backtest.key_metrics.expected_daily) || "N/A" }}
          </div>

          <div class="text-muted">Expected Monthly</div>
          <div class="text-right">
            {{
              formatPercentage(backtest.key_metrics.expected_monthly) || "N/A"
            }}
          </div>

          <div class="text-muted">Expected Yearly</div>
          <div class="text-right">
            {{
              formatPercentage(backtest.key_metrics.expected_yearly) || "N/A"
            }}
          </div>

          <!-- Divider -->
          <div class="col-span-2 mt-4 mb-1">
            <div class="h-px bg-base-300"></div>
          </div>

          <!-- Risk Metrics -->
          <div class="text-muted">Kelly Criterion</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.kelly_criterion) || "N/A" }}
          </div>

          <div class="text-muted">Risk of Ruin</div>
          <div class="text-right">
            {{ formatPercentage(backtest.key_metrics.risk_of_ruin) || "N/A" }}
          </div>

          <div class="text-muted">Value at Risk</div>
          <div class="text-right">
            {{ formatPercentage(backtest.key_metrics.value_at_risk) || "N/A" }}
          </div>

          <div class="text-muted">Expected Shortfall</div>
          <div class="text-right">
            {{
              formatPercentage(backtest.key_metrics.expected_shortfall) || "N/A"
            }}
          </div>

          <!-- Divider -->
          <div class="col-span-2 mt-4 mb-1">
            <div class="h-px bg-base-300"></div>
          </div>

          <!-- Trading Metrics -->
          <div class="text-muted">Gain/Pain Ratio</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.gain_to_pain) || "N/A" }}
          </div>

          <div class="text-muted">Gain/Pain (1M)</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.gain_to_pain_1m) || "N/A" }}
          </div>

          <div class="text-muted">Tail Ratio</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.tail_ratio) || "N/A" }}
          </div>

          <div class="text-muted">Common Sense Ratio</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.common_sense_ratio) || "N/A" }}
          </div>

          <div class="text-muted">CPC Index</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.cpc_index) || "N/A" }}
          </div>

          <div class="text-muted">Outlier Win Ratio</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.outlier_win_ratio) || "N/A" }}
          </div>

          <div class="text-muted">Outlier Loss Ratio</div>
          <div class="text-right">
            {{ formatNumber(backtest.key_metrics.outlier_loss_ratio) || "N/A" }}
          </div>
        </div>
      </div>

      <!-- Yearly Returns -->
      <div
        class="card bg-card rounded-lg p-6 border border-base-300"
        v-if="backtest.key_metrics.yearly_returns"
      >
        <h3 class="text-lg font-semibold mb-4">Yearly Returns</h3>
        <div class="grid grid-cols-2 gap-4">
          <div class="text-muted font-medium">Year</div>
          <div class="text-muted text-right font-medium">Return</div>

          <template
            v-for="(return_value, year) in backtest.key_metrics.yearly_returns"
            :key="year"
          >
            <div class="text-muted">{{ year }}</div>
            <div class="text-right">{{ formatPercentage(return_value) }}</div>
          </template>
        </div>
      </div>

      <!-- Drawdown Details -->
      <div
        class="card bg-card rounded-lg p-6 border border-base-300"
        v-if="backtest.key_metrics.drawdown_details"
      >
        <h3 class="text-lg font-semibold mb-4">Top 10 Drawdowns</h3>
        <div class="grid grid-cols-4 gap-4">
          <div class="text-muted font-medium">Start</div>
          <div class="text-muted font-medium">End</div>
          <div class="text-muted text-right font-medium">Length</div>
          <div class="text-muted text-right font-medium">Drawdown</div>

          <template
            v-for="(dd, index) in backtest.key_metrics.drawdown_details"
            :key="index"
          >
            <div class="text-muted">{{ formatDate(dd.start) }}</div>
            <div class="text-muted">{{ formatDate(dd.end) }}</div>
            <div class="text-right">{{ dd.length }} days</div>
            <div class="text-right">{{ formatPercentage(dd.drawdown) }}</div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-2/5 text-center py-8"
    >
      <p class="text-muted">No metrics data available</p>
    </div>
  </div>
</template>

<script>
import {
  createChart,
  LineSeries,
  HistogramSeries,
  BaselineSeries,
  AreaSeries,
} from "lightweight-charts";
import { onMounted, ref, onBeforeUnmount } from "vue";
import { Chart } from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  BoxPlotController,
  BoxAndWiskers,
} from "@sgratzl/chartjs-chart-boxplot";
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(annotationPlugin);
Chart.register(BoxPlotController);
Chart.register(BoxAndWiskers);
Chart.register(zoomPlugin);

export default {
  name: "BacktestReportTab",
  props: {
    backtest: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const chartContainer = ref(null);
    const logChartContainer = ref(null);
    const volChartContainer = ref(null);
    const sharpeChartContainer = ref(null);
    const sortinoChartContainer = ref(null);
    const drawdownChartContainer = ref(null);
    const underwaterChartContainer = ref(null);
    const eoyBarChart = ref(null);
    const monthlyDistChart = ref(null);
    const dailyChartContainer = ref(null);
    const returnQuartilesChart = ref(null);
    let chart = null;
    let logChart = null;
    let volChart = null;
    let sharpeChart = null;
    let sortinoChart = null;
    let drawdownChart = null;
    let underwaterChart = null;
    let dailyChart = null;
    let barChart = null;
    let distributionChart = null;
    let quartilesChart = null;

    const formatPercentage = (value) => {
      if (value === null || value === undefined) return "N/A";
      return `${(value * 100).toFixed(2)}%`;
    };

    const formatNumber = (value) => {
      if (value === null || value === undefined) return "N/A";
      return value.toFixed(2);
    };

    const formatDate = (dateStr) => {
      if (!dateStr) return "N/A";
      const date = new Date(dateStr);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };

    const getHeatmapColor = (value) => {
      if (value === null || value === undefined) return "transparent";
      if (value === 0) return "transparent";

      const intensity = Math.min(Math.abs(value) / 20, 1); // Scale intensity based on percentage (max at 20%)
      if (value > 0) {
        return `rgba(34, 197, 94, ${intensity * 0.2})`; // Green
      } else {
        return `rgba(239, 68, 68, ${intensity * 0.2})`; // Red
      }
    };

    const getTextColor = (value) => {
      if (value === null || value === undefined) return "#6B7280";
      if (value === 0) return "#6B7280";
      return value > 0 ? "#16A34A" : "#DC2626";
    };

    const formatHeatmapValue = (value) => {
      if (value === null || value === undefined || value === 0) return "0.00";
      return value.toFixed(2); // Value is already in percentage form from backend
    };

    const initializeCharts = async () => {
      if (
        chartContainer.value &&
        logChartContainer.value &&
        volChartContainer.value &&
        sharpeChartContainer.value &&
        sortinoChartContainer.value &&
        drawdownChartContainer.value &&
        underwaterChartContainer.value &&
        eoyBarChart.value &&
        monthlyDistChart.value &&
        dailyChartContainer.value &&
        returnQuartilesChart.value
      ) {
        // Initialize regular chart
        chart = createChart(chartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            ticksVisible: true,
            formatPrice: (price) => `${price.toFixed(2)}%`,
            title: "Cumulative Return (%)",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
          localization: {
            priceFormatter: (price) => `${price.toFixed(2)}%`,
          },
          crosshair: {
            horzLine: {
              labelVisible: true,
              labelFormatter: (price) => `${price.toFixed(2)}%`,
            },
          },
        });

        // Initialize log chart
        logChart = createChart(logChartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            mode: 2, // Logarithmic
            invertScale: false,
            alignLabels: true,
            scaleMargins: {
              top: 0.2,
              bottom: 0.2,
            },
            ticksVisible: true,
            formatPrice: (price) => `${price.toFixed(2)}%`,
            title: "Log Return (%)",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
          localization: {
            priceFormatter: (price) => `${price.toFixed(2)}%`,
          },
          crosshair: {
            horzLine: {
              labelVisible: true,
              labelFormatter: (price) => `${price.toFixed(2)}%`,
            },
          },
        });

        // Initialize volatility matched chart
        volChart = createChart(volChartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            ticksVisible: true,
            formatPrice: (price) => `${price.toFixed(2)}%`,
            title: "Return (%)",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
          localization: {
            priceFormatter: (price) => `${price.toFixed(2)}%`,
          },
          crosshair: {
            horzLine: {
              labelVisible: true,
              labelFormatter: (price) => `${price.toFixed(2)}%`,
            },
          },
        });

        // Initialize Sharpe chart
        sharpeChart = createChart(sharpeChartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            ticksVisible: true,
            formatPrice: (price) => price.toFixed(2),
            title: "Rolling Sharpe Ratio",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
        });

        // Initialize Sortino chart
        sortinoChart = createChart(sortinoChartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            ticksVisible: true,
            formatPrice: (price) => price.toFixed(2),
            title: "Rolling Sortino Ratio",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
        });

        // Initialize drawdown chart
        drawdownChart = createChart(drawdownChartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            ticksVisible: true,
            formatPrice: (price) => `${price.toFixed(2)}%`,
            title: "Return (%)",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
          localization: {
            priceFormatter: (price) => `${price.toFixed(2)}%`,
          },
          crosshair: {
            horzLine: {
              labelVisible: true,
              labelFormatter: (price) => `${price.toFixed(2)}%`,
            },
          },
        });

        // Initialize underwater chart
        underwaterChart = createChart(underwaterChartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            ticksVisible: true,
            formatPrice: (price) => `${price.toFixed(2)}%`,
            title: "Drawdown (%)",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
          localization: {
            priceFormatter: (price) => `${price.toFixed(2)}%`,
          },
          crosshair: {
            horzLine: {
              labelVisible: true,
              labelFormatter: (price) => `${price.toFixed(2)}%`,
            },
          },
        });

        const underwaterSeries = underwaterChart.addSeries(BaselineSeries, {
          baseValue: { type: "price", price: 0 },
          topLineColor: "rgba(33, 150, 243, 0.8)",
          topFillColor1: "rgba(33, 150, 243, 0.28)",
          topFillColor2: "rgba(33, 150, 243, 0.05)",
          bottomLineColor: "rgba(33, 150, 243, 0.8)",
          bottomFillColor1: "rgba(33, 150, 243, 0.28)",
          bottomFillColor2: "rgba(33, 150, 243, 0.05)",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        const averageDrawdownSeries = underwaterChart.addSeries(LineSeries, {
          color: "#FF4949",
          lineWidth: 2,
          lineStyle: 2, // Dashed
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        // Add series for strategy and benchmark
        const strategySeries = chart.addSeries(LineSeries, {
          color: "#2196F3",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        const benchmarkSeries = chart.addSeries(LineSeries, {
          color: "#FFB74D",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        const strategyLogSeries = logChart.addSeries(LineSeries, {
          color: "#2196F3",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        const benchmarkLogSeries = logChart.addSeries(LineSeries, {
          color: "#FFB74D",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        const strategyVolSeries = volChart.addSeries(LineSeries, {
          color: "#2196F3",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        const benchmarkVolSeries = volChart.addSeries(LineSeries, {
          color: "#FFB74D",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        const sharpeSeries = sharpeChart.addSeries(LineSeries, {
          color: "#2196F3",
          lineWidth: 2,
          priceScaleId: "left",
        });

        const sortinoSeries = sortinoChart.addSeries(LineSeries, {
          color: "#2196F3",
          lineWidth: 2,
          priceScaleId: "left",
        });

        const drawdownSeries = drawdownChart.addSeries(LineSeries, {
          color: "#2196F3",
          lineWidth: 2,
          priceScaleId: "left",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
        });

        // Create separate area series for each drawdown period
        const drawdownAreaSeries = [];

        // Initialize daily returns chart
        dailyChart = createChart(dailyChartContainer.value, {
          height: 300,
          layout: {
            background: { color: "transparent" },
            textColor: "#D1D5DB",
          },
          grid: {
            vertLines: { color: "rgba(42, 46, 57, 0.5)" },
            horzLines: { color: "rgba(42, 46, 57, 0.5)" },
          },
          leftPriceScale: {
            visible: true,
            borderColor: "rgba(107, 114, 128, 0.3)",
            borderVisible: true,
            autoScale: true,
            ticksVisible: true,
            formatPrice: (price) => `${price.toFixed(2)}%`,
            title: "Daily Return (%)",
          },
          rightPriceScale: {
            visible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            tickMarkFormatter: (time) => {
              const date = new Date(time * 1000);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              });
            },
          },
          localization: {
            priceFormatter: (price) => `${price.toFixed(2)}%`,
          },
          crosshair: {
            horzLine: {
              labelVisible: true,
              labelFormatter: (price) => `${price.toFixed(2)}%`,
            },
          },
        });

        // Add daily returns series
        const dailyReturnsSeries = dailyChart.addSeries(HistogramSeries, {
          color: "#2196F3",
          priceFormat: {
            type: "price",
            precision: 2,
            minMove: 0.01,
            formatter: (price) => `${price.toFixed(2)}%`,
          },
          priceScaleId: "left",
        });

        // Load and set data
        if (props.backtest?.chart_data) {
          const chartData = props.backtest.chart_data;

          // Set cumulative returns data
          if (chartData.cumulative_returns?.data) {
            strategySeries.setData(chartData.cumulative_returns.data.strategy);
            if (chartData.cumulative_returns.data.benchmark) {
              benchmarkSeries.setData(
                chartData.cumulative_returns.data.benchmark
              );
            }
          }

          // Set cumulative returns log data
          if (chartData.cumulative_returns_log?.data) {
            // Transform data to ensure positive values for log scale
            const minValue = Math.min(
              ...chartData.cumulative_returns_log.data.strategy.map(
                (d) => d.value
              ),
              ...(chartData.cumulative_returns_log.data.benchmark?.map(
                (d) => d.value
              ) || [])
            );

            const offset = minValue < 1 ? Math.abs(minValue) + 1 : 0;

            const transformedStrategyData =
              chartData.cumulative_returns_log.data.strategy.map((d) => ({
                time: d.time,
                value: d.value + offset,
              }));

            strategyLogSeries.setData(transformedStrategyData);

            if (chartData.cumulative_returns_log.data.benchmark) {
              const transformedBenchmarkData =
                chartData.cumulative_returns_log.data.benchmark.map((d) => ({
                  time: d.time,
                  value: d.value + offset,
                }));
              benchmarkLogSeries.setData(transformedBenchmarkData);
            }

            // Force an immediate rescale after setting data
            logChart.timeScale().fitContent();
            setTimeout(() => {
              logChart.timeScale().scrollToPosition(0, false);
            }, 100);
          }

          // Set volatility matched data
          if (chartData.volatility_matched?.data) {
            strategyVolSeries.setData(
              chartData.volatility_matched.data.strategy
            );
            if (chartData.volatility_matched.data.benchmark) {
              benchmarkVolSeries.setData(
                chartData.volatility_matched.data.benchmark
              );
            }
          }

          // Set rolling volatility data
          if (chartData.rolling_volatility?.data) {
            if (chartData.rolling_volatility.data.strategy) {
              strategyVolSeries.setData(
                chartData.rolling_volatility.data.strategy
              );
            }
            if (chartData.rolling_volatility.data.benchmark) {
              benchmarkVolSeries.setData(
                chartData.rolling_volatility.data.benchmark
              );
            }
          }

          // Set rolling Sharpe data
          if (chartData.rolling_sharpe?.data?.strategy) {
            sharpeSeries.setData(chartData.rolling_sharpe.data.strategy);
          }

          // Set rolling Sortino data
          if (chartData.rolling_sortino?.data?.strategy) {
            sortinoSeries.setData(chartData.rolling_sortino.data.strategy);
          }

          // Set drawdown chart data
          if (chartData.drawdown_chart?.data) {
            // Set returns line
            drawdownSeries.setData(chartData.drawdown_chart.data.returns);

            // Clear any existing drawdown area series
            drawdownAreaSeries.forEach((series) => series.remove());
            drawdownAreaSeries.length = 0;

            // Create area series for each drawdown period
            chartData.drawdown_chart.data.drawdown_periods.forEach((period) => {
              const areaSeries = drawdownChart.addSeries(AreaSeries, {
                lastValueVisible: false,
                priceLineVisible: false,
                crosshairMarkerVisible: false,
                lineColor: "rgba(255, 73, 73, 0.4)",
                topColor: "rgba(255, 73, 73, 0.2)",
                bottomColor: "rgba(255, 73, 73, 0.2)",
                priceScaleId: "left",
              });

              // Filter returns data for this period
              const periodData = chartData.drawdown_chart.data.returns.filter(
                (point) =>
                  point.time >= period.start && point.time <= period.end
              );

              areaSeries.setData(periodData);
              drawdownAreaSeries.push(areaSeries);
            });
          }

          // Set underwater chart data
          if (chartData.underwater_plot?.data) {
            underwaterSeries.setData(chartData.underwater_plot.data.drawdowns);

            // Create average line data spanning the entire time range
            if (chartData.underwater_plot.data.drawdowns.length > 0) {
              const avgValue = chartData.underwater_plot.data.average;
              const avgLine = [
                {
                  time: chartData.underwater_plot.data.drawdowns[0].time,
                  value: avgValue,
                },
                {
                  time: chartData.underwater_plot.data.drawdowns[
                    chartData.underwater_plot.data.drawdowns.length - 1
                  ].time,
                  value: avgValue,
                },
              ];
              averageDrawdownSeries.setData(avgLine);
            }
          }

          // Set daily returns data if available
          if (chartData.daily_returns?.data?.strategy) {
            dailyReturnsSeries.setData(chartData.daily_returns.data.strategy);
          }

          // Fit all charts
          chart.timeScale().fitContent();
          logChart.timeScale().fitContent();
          volChart.timeScale().fitContent();
          sharpeChart.timeScale().fitContent();
          sortinoChart.timeScale().fitContent();
          drawdownChart.timeScale().fitContent();
          underwaterChart.timeScale().fitContent();
        }

        // Initialize bar chart
        if (props.backtest?.chart_data?.eoy_returns?.data) {
          const eoyData = props.backtest.chart_data.eoy_returns.data;
          const years = eoyData.strategy.map((item) =>
            new Date(item.time * 1000).getFullYear()
          );
          const strategyReturns = eoyData.strategy.map((item) => item.value);
          const benchmarkReturns =
            eoyData.benchmark?.map((item) => item.value) || [];

          const barChartData = {
            labels: years,
            datasets: [
              {
                label: "Strategy",
                data: strategyReturns,
                backgroundColor: "rgba(33, 150, 243, 0.5)",
                borderColor: "rgb(33, 150, 243)",
                borderWidth: 1,
              },
            ],
          };

          if (benchmarkReturns.length > 0) {
            barChartData.datasets.push({
              label: "Benchmark",
              data: benchmarkReturns,
              backgroundColor: "rgba(255, 183, 77, 0.5)",
              borderColor: "rgb(255, 183, 77)",
              borderWidth: 1,
            });
          }

          if (barChart) {
            barChart.destroy();
          }

          barChart = new Chart(eoyBarChart.value, {
            type: "bar",
            data: barChartData,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    color: "rgba(42, 46, 57, 0.5)",
                  },
                  ticks: {
                    color: "#D1D5DB",
                    callback: function (value) {
                      return value + "%";
                    },
                  },
                  title: {
                    display: true,
                    text: "Return (%)",
                    color: "#D1D5DB",
                  },
                },
                x: {
                  grid: {
                    color: "rgba(42, 46, 57, 0.5)",
                  },
                  ticks: {
                    color: "#D1D5DB",
                  },
                  title: {
                    display: true,
                    text: "Year",
                    color: "#D1D5DB",
                  },
                },
              },
            },
          });
        }

        // Initialize monthly distribution chart
        if (props.backtest?.chart_data?.monthly_distribution?.data) {
          const distData = props.backtest.chart_data.monthly_distribution.data;

          const distributionData = {
            labels: distData.strategy.x_coords.map((x) => x.toFixed(0) + "%"),
            datasets: [
              {
                type: "bar",
                label: "Monthly Returns",
                data: distData.strategy.x_coords.map((x, i) => ({
                  x: x,
                  y: distData.strategy.frequency[i],
                })),
                backgroundColor: "rgba(33, 150, 243, 0.3)",
                borderColor: "rgba(33, 150, 243, 0.5)",
                borderWidth: 1,
                barPercentage: 0.9,
                categoryPercentage: 1,
              },
            ],
          };

          if (distributionChart) {
            distributionChart.destroy();
          }

          distributionChart = new Chart(monthlyDistChart.value, {
            type: "bar",
            data: distributionData,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return `${context.raw.y} months`;
                    },
                  },
                },
                annotation: {
                  common: {
                    drawTime: "afterDraw",
                  },
                  annotations: {
                    averageLine: {
                      type: "line",
                      scaleID: "x",
                      value: distData.strategy.average,
                      borderColor: "#FF4949",
                      borderWidth: 2,
                      borderDash: [5, 5],
                      drawTime: "afterDatasetsDraw",
                    },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                    drawBorder: false,
                  },
                  border: {
                    display: false,
                  },
                  ticks: {
                    stepSize: 2,
                    color: "#D1D5DB",
                    font: {
                      size: 11,
                    },
                  },
                  title: {
                    display: true,
                    text: "Number of Months",
                    color: "#D1D5DB",
                    font: {
                      size: 12,
                    },
                  },
                },
                x: {
                  type: "linear",
                  offset: false,
                  grid: {
                    display: true,
                    color: "rgba(42, 46, 57, 0.5)",
                    drawBorder: false,
                  },
                  border: {
                    display: false,
                  },
                  ticks: {
                    stepSize: 5,
                    color: "#D1D5DB",
                    font: {
                      size: 11,
                    },
                    callback: function (value) {
                      return value + "%";
                    },
                  },
                  title: {
                    display: true,
                    text: "Monthly Return (%)",
                    color: "#D1D5DB",
                    font: {
                      size: 12,
                    },
                  },
                  min: -40,
                  max: 60,
                },
              },
            },
          });
        }

        // Initialize return quartiles chart
        if (props.backtest?.chart_data?.return_quartiles?.data) {
          const quartilesData = props.backtest.chart_data.return_quartiles.data;
          const periods = ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"];

          // Define colors for each period
          const periodColors = {
            Daily: {
              background: "rgba(59, 130, 246, 0.5)",
              border: "rgba(59, 130, 246, 0.8)",
            },
            Weekly: {
              background: "rgba(16, 185, 129, 0.5)",
              border: "rgba(16, 185, 129, 0.8)",
            },
            Monthly: {
              background: "rgba(245, 158, 11, 0.5)",
              border: "rgba(245, 158, 11, 0.8)",
            },
            Quarterly: {
              background: "rgba(139, 92, 246, 0.5)",
              border: "rgba(139, 92, 246, 0.8)",
            },
            Yearly: {
              background: "rgba(236, 72, 153, 0.5)",
              border: "rgba(236, 72, 153, 0.8)",
            },
          };

          const boxplotData = periods.map((period) => {
            const periodData = quartilesData[period.toLowerCase()];
            if (!periodData) return null;

            return {
              min: periodData.min,
              q1: periodData.q1,
              median: periodData.median,
              q3: periodData.q3,
              max: periodData.max,
              outliers: periodData.outliers || [],
            };
          });

          if (quartilesChart) {
            quartilesChart.destroy();
          }

          quartilesChart = new Chart(returnQuartilesChart.value, {
            type: "boxplot",
            data: {
              labels: periods,
              datasets: [
                {
                  data: boxplotData,
                  backgroundColor: periods.map(
                    (p) => periodColors[p].background
                  ),
                  borderColor: periods.map((p) => periodColors[p].border),
                  borderWidth: 1,
                  outlierBackgroundColor: "rgba(75, 85, 99, 0.8)",
                  outlierBorderColor: "rgba(75, 85, 99, 0.8)",
                  outlierRadius: 3,
                  itemRadius: 0,
                  medianColor: periods.map((p) => periodColors[p].border),
                  meanBackgroundColor: periods.map(
                    (p) => periodColors[p].border
                  ),
                  lowerBackgroundColor: periods.map(
                    (p) => periodColors[p].background
                  ),
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value = context.raw;
                      if (!value) return "";

                      const labels = [];
                      labels.push(`Max: ${value.max.toFixed(2)}%`);
                      labels.push(`Q3: ${value.q3.toFixed(2)}%`);
                      labels.push(`Median: ${value.median.toFixed(2)}%`);
                      labels.push(`Q1: ${value.q1.toFixed(2)}%`);
                      labels.push(`Min: ${value.min.toFixed(2)}%`);
                      if (value.outliers?.length > 0) {
                        labels.push(`Outliers: ${value.outliers.length}`);
                      }
                      return labels;
                    },
                  },
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: "y",
                  },
                  zoom: {
                    wheel: {
                      enabled: true,
                      modifierKey: "ctrl",
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: "y",
                  },
                  limits: {
                    y: { min: "original", max: "original" },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    color: "rgba(42, 46, 57, 0.5)",
                  },
                  ticks: {
                    color: "#D1D5DB",
                    callback: function (value) {
                      return value + "%";
                    },
                  },
                  title: {
                    display: true,
                    text: "Return (%)",
                    color: "#D1D5DB",
                  },
                },
                x: {
                  grid: {
                    color: "rgba(42, 46, 57, 0.5)",
                  },
                  ticks: {
                    color: "#D1D5DB",
                  },
                },
              },
            },
          });
        }

        // Handle window resize
        const handleResize = () => {
          if (chartContainer.value) {
            chart.resize(chartContainer.value.clientWidth, 300);
          }
          if (logChartContainer.value) {
            logChart.resize(logChartContainer.value.clientWidth, 300);
          }
          if (volChartContainer.value) {
            volChart.resize(volChartContainer.value.clientWidth, 300);
          }
          if (sharpeChartContainer.value) {
            sharpeChart.resize(sharpeChartContainer.value.clientWidth, 300);
          }
          if (sortinoChartContainer.value) {
            sortinoChart.resize(sortinoChartContainer.value.clientWidth, 300);
          }
          if (drawdownChartContainer.value) {
            drawdownChart.resize(drawdownChartContainer.value.clientWidth, 300);
          }
          if (underwaterChartContainer.value) {
            underwaterChart.resize(
              underwaterChartContainer.value.clientWidth,
              300
            );
          }
          if (dailyChartContainer.value) {
            dailyChart.resize(dailyChartContainer.value.clientWidth, 300);
          }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        // Cleanup on unmount
        onBeforeUnmount(() => {
          window.removeEventListener("resize", handleResize);
          if (chart) {
            chart.remove();
          }
          if (logChart) {
            logChart.remove();
          }
          if (volChart) {
            volChart.remove();
          }
          if (sharpeChart) {
            sharpeChart.remove();
          }
          if (sortinoChart) {
            sortinoChart.remove();
          }
          if (drawdownChart) {
            drawdownChart.remove();
          }
          if (underwaterChart) {
            underwaterChart.remove();
          }
          if (dailyChart) {
            dailyChart.remove();
          }
          if (barChart) {
            barChart.destroy();
          }
          if (distributionChart) {
            distributionChart.destroy();
          }
          if (quartilesChart) {
            quartilesChart.destroy();
          }
        });
      }
    };

    const fitContent = () => {
      if (chart) {
        chart.timeScale().fitContent();
      }
    };

    const fitLogContent = () => {
      if (logChart) {
        logChart.timeScale().fitContent();
      }
    };

    const fitVolContent = () => {
      if (volChart) {
        volChart.timeScale().fitContent();
      }
    };

    const fitSharpeContent = () => {
      if (sharpeChart) {
        sharpeChart.timeScale().fitContent();
      }
    };

    const fitSortinoContent = () => {
      if (sortinoChart) {
        sortinoChart.timeScale().fitContent();
      }
    };

    const fitDrawdownContent = () => {
      if (drawdownChart) {
        drawdownChart.timeScale().fitContent();
      }
    };

    const fitUnderwaterContent = () => {
      if (underwaterChart) {
        underwaterChart.timeScale().fitContent();
      }
    };

    const fitDailyContent = () => {
      if (dailyChart) {
        dailyChart.timeScale().fitContent();
      }
    };

    onMounted(() => {
      initializeCharts();
    });

    return {
      formatPercentage,
      formatNumber,
      formatDate,
      getHeatmapColor,
      getTextColor,
      formatHeatmapValue,
      chartContainer,
      logChartContainer,
      volChartContainer,
      sharpeChartContainer,
      sortinoChartContainer,
      drawdownChartContainer,
      underwaterChartContainer,
      eoyBarChart,
      monthlyDistChart,
      dailyChartContainer,
      returnQuartilesChart,
      fitContent,
      fitLogContent,
      fitVolContent,
      fitSharpeContent,
      fitSortinoContent,
      fitDrawdownContent,
      fitUnderwaterContent,
      fitDailyContent,
    };
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
