<template>
  <div
    v-if="messages.length > 0"
    class="toast-container"
    :class="{ 'cursor-pointer': !expanded, expanded: expanded }"
    @click="expandStack"
  >
    <TransitionGroup
      name="toast"
      tag="div"
      class="toast-group"
      appear
      @before-leave="setLowZIndex"
    >
      <div
        v-for="(notification, index) in messages"
        :key="notification.id"
        class="toast-message"
        :style="{
          zIndex: notification.zIndex ?? messages.length - index,
          transform: getTransform(index),
        }"
      >
        <!-- Wrapper for transition animations -->
        <div class="toast-wrapper">
          <div class="toast-content">
            <span>{{ notification.text }}</span>
            <button
              class="btn btn-circle btn-xs"
              @click.stop="removeMessage(notification.id)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useToast } from "@/composables/useToast";

const props = defineProps({
  message: {
    type: String,
    default: "",
  },
});

const expanded = ref(false);

const expandStack = () => {
  if (!expanded.value) {
    expanded.value = true;
  }
};

const { messages, addMessage, removeMessage } = useToast();

const getTransform = (index) => {
  if (expanded.value) {
    return `translateY(${index * 20}px)`;
  }
  return `translateY(${index * -10}px)`;
};

watch(
  () => props.message,
  (newMessage) => {
    if (newMessage && newMessage.trim() !== "") {
      addMessage(newMessage);
    }
  }
);

watch(
  () => messages.value,
  (newMessages) => {
    if (newMessages.length === 0) {
      expanded.value = false;
    }
  },
  { deep: true }
);

const setLowZIndex = (el) => {
  el.style.zIndex = "-100";
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
  z-index: 9999;
  padding: 1rem;
  display: flex;
  flex-direction: column-reverse;
  pointer-events: auto;
  padding-bottom: 2.5rem;
  width: calc(100% - 2rem);
  max-width: 400px;
}

.toast-group {
  display: flex;
  flex-direction: column-reverse;
  gap: 0.2rem;
  transition: gap 0.3s ease, transform 0.3s ease;
}

.toast-container.expanded .toast-group {
  gap: 3.2rem;
}

.toast-message {
  width: 100%;
  background-color: #17a16a;
  color: white;
  border-radius: 0.5rem;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.4),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06), 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  pointer-events: auto;
  margin-bottom: -2.5rem;
  transform: translateY(0);
}

.toast-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
}

.toast-content span {
  flex-grow: 1;
  word-wrap: break-word;
  margin-right: 0.5rem;
  font-size: 0.875rem;
}

.btn-circle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  flex-shrink: 0;
}

.btn-circle:hover {
  opacity: 1;
}

.toast-enter-active,
.toast-leave-active,
.toast-appear-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.toast-enter-from,
.toast-appear-from {
  transform: translateY(10px);
}

.toast-enter-to,
.toast-appear-to {
  transform: translateY(0);
}

.toast-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.toast-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.toast-move {
  transition: transform 0.5s ease;
}

@media (max-width: 640px) {
  .toast-container {
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    width: auto;
    max-width: none;
  }

  .toast-message {
    width: 100%;
  }

  .toast-content span {
    font-size: 0.8125rem;
  }

  .btn-circle {
    transform: scale(0.9);
  }
}
</style>
