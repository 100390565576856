import walletService from "@/services/walletService";
import store from "@/store";

export const defaultFields = {
  order_size: {
    name: "order_size",
    type: "smart",
    formula: async (config, context) => {
      if (!context?.apiKeyId || !context?.symbol) {
        return "0";
      }

      try {
        // Get wallet balance
        const walletInfo = await walletService.getWalletBalance(
          context.apiKeyId,
          context.symbol
        );
        const balance = walletInfo[walletInfo.currency] || 0;

        // Get wallet type (spot vs inverse)
        const walletType = walletService.getWalletType(context.symbol);

        // Get current BTC price
        await store.dispatch("price/fetchBTCPrice");
        const btcPrice = store.getters["price/currentBTCPrice"];

        if (!btcPrice) {
          console.error("Unable to fetch BTC price");
          return "0";
        }

        // Calculate order size based on wallet type
        const nLevels = config.n_levels || 1;
        let orderSize;

        if (walletType === "spot") {
          // For spot: Convert USDT balance to BTC
          orderSize = balance / btcPrice / nLevels;
        } else {
          // For inverse: Convert BTC balance to USD
          orderSize = (balance * btcPrice) / nLevels;
        }

        // Format to appropriate decimal places
        const decimals = walletType === "spot" ? 8 : 2;
        return orderSize.toFixed(decimals);
      } catch (error) {
        console.error("Error calculating order size:", error);
        return "0";
      }
    },
    format: (value, symbol, marketType) => {
      if (!value || !symbol || isNaN(Number(value)) || !isFinite(value)) {
        return "...";
      }

      // Determine the wallet type if not provided
      const walletType = marketType || walletService.getWalletType(symbol);

      // Format based on wallet type
      if (walletType === "inverse") {
        // For inverse markets, show in USD
        return `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value)}`;
      } else {
        // For spot markets, show in BTC
        return `₿${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 8,
          maximumFractionDigits: 8,
        }).format(value)}`;
      }
    },
    dependencies: ["n_levels"],
  },
};

export const getDefaultFields = () => {
  return Object.values(defaultFields).map((field) => ({
    name: field.name,
    type: field.type,
    formula: field.formula,
    dependencies: field.dependencies,
  }));
};

export const getDefaultFieldValue = async (
  fieldName,
  config = null,
  context = {}
) => {
  const field = defaultFields[fieldName];
  if (!field) {
    return { value: "0", missingFields: [], type: "decimal" };
  }

  try {
    // Create a normalized config object from the input
    let normalizedConfig = {};
    if (Array.isArray(config)) {
      // If config is an array of fields (from strategy)
      config.forEach((field) => {
        if (field.name && field.default_value) {
          normalizedConfig[field.name] = field.default_value;
        }
      });
    } else if (typeof config === "object" && config !== null) {
      // If config is already an object
      normalizedConfig = { ...config };
    }

    console.log("getDefaultFieldValue called with:", {
      fieldName,
      originalConfig: config,
      normalizedConfig,
    });

    // Handle both string formulas and function formulas
    const value =
      typeof field.formula === "function"
        ? await field.formula(normalizedConfig, context)
        : await evaluateStringFormula(field.formula, normalizedConfig);

    return {
      value,
      missingFields: [],
      type: field.type,
    };
  } catch (error) {
    console.error(`Error in getDefaultFieldValue for ${fieldName}:`, error);
    return { value: "0", missingFields: [], type: "decimal" };
  }
};

const evaluateStringFormula = async (formula, config) => {
  let calculatedFormula = formula;
  Object.entries(config).forEach(([key, value]) => {
    calculatedFormula = calculatedFormula.replace(
      new RegExp(`\\{${key}\\}`, "g"),
      value
    );
  });
  return new Function(`return ${calculatedFormula}`)().toString();
};

export const getDefaultFieldFormat = (fieldName, value, symbol) => {
  const field = defaultFields[fieldName];
  if (!field?.format) return null;

  return field.format(value, symbol);
};
