// Remove the useStore import
// import { useStore } from "vuex"; // Remove this line

// Define available types and their configurations
export const FIELD_TYPES = {
  price: { label: "Price" },
  quantity: { label: "Quantity" },
  int: { label: "Integer" },
  string: { label: "String" },
  decimal: { label: "Decimal" },
  smart: { label: "Smart" },
  time: { label: "Time" },
  percent: { label: "Percent" },
  multiplier: { label: "Multiplier" },
  dropdown: { label: "Dropdown" },
};

// Import formatting utilities
import { defaultCurrencyRounding } from "@/utils/formatting";

// Clean numeric values (remove currency symbols, etc)
export function cleanNumericValue(value) {
  if (typeof value !== "string") {
    return value;
  }
  // Remove currency symbols, commas, and percentage signs, then parse
  const cleanValue = parseFloat(value.replace(/[₿$%,⟠]/g, ""));
  // Return the cleaned value
  return cleanValue;
}

// Format value based on type and symbol
export function formatValue(value, type, symbol, store) {
  // string types dont need any extra formatting
  if(type === "string") {
    return value;
  }

  const getSymbolInfo = (symbol) => {
    // Add null check for store and return empty object if store is not available
    if (!store?.getters?.["symbols/getSymbolInfo"]) {
      console.warn("Store not available for symbol info lookup");
      return {};
    }
    return store.getters["symbols/getSymbolInfo"](symbol);
  };

  if (
    value === null ||
    value === undefined ||
    value === "" ||
    isNaN(value) ||
    !isFinite(value)
  ) {
    return "";
  }

  const numericValue = cleanNumericValue(value);

  switch (type) {
    case "int":
      return new Intl.NumberFormat("en-US").format(Math.round(numericValue));
    case "decimal":
      return parseFloat(numericValue.toFixed(8)).toString();
    case "price": {
      const hasDecimal = numericValue % 1 !== 0;
      return `$${new Intl.NumberFormat("en-US", {
        minimumFractionDigits: hasDecimal ? 2 : 0,
        maximumFractionDigits: 2,
      }).format(numericValue)}`;
    }
    case "quantity": {
      if (!symbol) {
        return numericValue.toString();
      }

      const symbolInfo = getSymbolInfo(symbol);
      const isInverse = !symbol.includes("USDT");

      if (isInverse) {
        // For inverse markets, show in USD
        return `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(numericValue)}`;
      } else {
        // For spot markets, show in base currency
        const baseCurrency = symbolInfo?.base || symbol.split("-")[0] || "BTC";
        const decimals = defaultCurrencyRounding[baseCurrency] || 8;
        const formattedValue = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(numericValue);
        return `${formattedValue} ${baseCurrency}`;
      }
    }
    case "time": {
      const hours = numericValue / 60 / 60;
      if (hours === 1) {
        return "1 hour";
      }
      return `${new Intl.NumberFormat("en-US").format(hours)} hours`;
    }
    case "percent": {
      // Value is stored as decimal (e.g. 0.05 for 5%), multiply by 100 for display
      return `${parseFloat((numericValue * 100).toFixed(2))}%`;
    }
    case "multiplier": {
      const rounded = Math.round(numericValue * 100) / 100;
      return `${rounded}x`;
    }
    case "dropdown": {
      // For dropdown type, just return the value as is
      // It should already be the selected option
      return value?.toString() || "";
    }
    default:
      return new Intl.NumberFormat("en-US").format(numericValue);
  }
}

// Convert value when changing types
export function convertValue(oldValue, oldType, newType) {
  if (!oldValue) return "";

  try {
    const cleanValue = cleanNumericValue(oldValue);
    return formatValue(cleanValue, newType);
  } catch (error) {
    console.error("Error converting value:", error);
    return "";
  }
}

// Get available field types for strategy fields
export function getStrategyFieldTypes() {
  return [
    { value: "price", label: "Price" },
    { value: "quantity", label: "Quantity" },
    { value: "int", label: "Integer" },
    { value: "decimal", label: "Decimal" },
    { value: "string", label: "String" },
    { value: "time", label: "Time" },
    { value: "smart", label: "Smart Default" },
    { value: "percent", label: "Percent" },
    { value: "multiplier", label: "Multiplier" },
    { value: "dropdown", label: "Dropdown" },
  ];
}

// Get available field types for custom fields
export function getCustomFieldTypes() {
  return [
    { value: "price", label: "Price" },
    { value: "quantity", label: "Quantity" },
    { value: "decimal", label: "Decimal" },
    { value: "int", label: "Integer" },
    { value: "string", label: "String" },
    { value: "special", label: "Special Field" },
    { value: "time", label: "Time" },
    { value: "percent", label: "Percent" },
    { value: "multiplier", label: "Multiplier" },
    { value: "dropdown", label: "Dropdown" },
  ];
}
