<template>
  <div class="dropdown dropdown-hover dropdown-right">
    <span
      tabindex="0"
      :class="[
        'h-3 w-3 rounded-full inline-block animate-pulse-scale',
        statusClass,
      ]"
    />

    <!-- Rich Popover Content -->
    <div
      tabindex="0"
      class="dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-base-100/100 text-base-content border border-base-300"
    >
      <div class="card-body p-2 space-y-1">
        <div class="flex items-center justify-between">
          <span class="text-sm font-medium flex items-center gap-1">
            Pod Status
            <span v-if="!isRunning">🚨</span>
          </span>
          <span
            class="px-2 py-0.5 rounded text-xs"
            :class="podStatusClass"
          >
            {{ isRunning ? "Running" : "Stopped" }}
          </span>
        </div>

        <div class="flex items-center justify-between">
          <span class="text-sm flex items-center gap-1">
            Market Data
            <span v-if="botHealth?.connections?.market_data?.status === 'error'"
              >🚨</span
            >
          </span>
          <span
            class="px-2 py-0.5 rounded text-xs"
            :class="
              connectionStatusClass(botHealth?.connections?.market_data?.status)
            "
          >
            {{
              botHealth?.connections?.market_data?.connected
                ? "Connected"
                : "Disconnected"
            }}
          </span>
        </div>

        <div class="flex items-center justify-between">
          <span class="text-sm flex items-center gap-1">
            Exchange
            <span v-if="botHealth?.connections?.exchange?.status === 'error'"
              >🚨</span
            >
          </span>
          <span
            class="px-2 py-0.5 rounded text-xs"
            :class="
              connectionStatusClass(botHealth?.connections?.exchange?.status)
            "
          >
            {{
              botHealth?.connections?.exchange?.connected
                ? "Connected"
                : "Disconnected"
            }}
          </span>
        </div>

        <div class="flex items-center justify-between">
          <span class="text-sm flex items-center gap-1">
            Range
            <span v-if="!inRange">🚨</span>
          </span>
          <span
            class="px-2 py-0.5 rounded text-xs"
            :class="
              inRange ? 'bg-success/20 text-success' : 'bg-error/20 text-error'
            "
          >
            {{ inRange ? "In Range" : "Out of Range" }}
          </span>
        </div>

        <div class="divider my-0" />

        <div class="flex items-center justify-between">
          <span class="text-sm flex items-center gap-1">
            Grid Orders
            <span v-if="botHealth?.grid?.status === 'warning'">⚠️</span>
          </span>
          <span class="text-sm font-medium">
            {{ botHealth?.grid?.count || 0 }} /
            {{ botHealth?.grid?.max_orders || 0 }}
          </span>
        </div>

        <div class="flex items-center justify-between">
          <span class="text-sm">Active Levels</span>
          <span class="text-sm font-medium"
            >{{ botHealth?.grid?.active_levels || 0 }} /
            {{ botHealth?.grid?.max_orders || 0 }}</span
          >
        </div>

        <div class="flex items-center justify-between">
          <span class="text-sm flex items-center gap-1">
            Recent Errors
            <span v-if="botHealth?.errors?.status === 'error'">🚨</span>
            <span v-else-if="botHealth?.errors?.status === 'warning'">⚠️</span>
          </span>
          <span
            class="text-sm font-medium"
            :class="errorStatusClass(botHealth?.errors?.status)"
          >
            {{ botHealth?.errors?.count || 0 }}
          </span>
        </div>

        <div class="flex items-center justify-between">
          <span class="text-sm">Filled Orders (last snapshot)</span>
          <span class="text-sm font-medium">{{
            botHealth?.recent_order_count || 0
          }}</span>
        </div>

        <div class="divider my-0" />

        <div class="flex items-center justify-between">
          <span class="text-xs text-base-content/70 flex items-center gap-1">
            Last Check
            <span v-if="botHealth?.timestamp?.status === 'error'">🚨</span>
          </span>
          <span
            class="text-xs text-base-content/70"
            :class="{ 'text-error': botHealth?.timestamp?.status === 'error' }"
          >
            {{ formattedLastCheck }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDatetime } from "@/utils/formatting";

export default {
  props: {
    botHealth: {
      type: Object,
      required: true,
      default: () => ({
        status: "red",
        connections: {},
        errors: {},
        grid: {},
        timestamp: {},
      }),
    },
    isRunning: {
      type: Boolean,
      required: true,
    },
    inRange: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    statusClass() {
      // For debugging - we can remove this later
      console.log("Bot health status:", this.botHealth?.status);
      console.log("Is running:", this.isRunning);
      console.log("In range:", this.inRange);

      if (!this.isRunning) {
        return "bg-red-500";
      }
      if (!this.inRange) {
        return "bg-gray-500";
      }

      const status = this.botHealth?.status || "red";
      const statusClasses = {
        green: "bg-green-500",
        amber: "bg-amber-500",
        red: "bg-red-500",
      };

      return statusClasses[status] || statusClasses.red;
    },

    podStatusClass() {
      return this.isRunning
        ? "bg-success/20 text-success"
        : "bg-error/20 text-error";
    },

    formattedLastCheck() {
      if (!this.botHealth?.timestamp) return "Unknown";
      return formatDatetime(this.botHealth.timestamp.timestamp);
    },
  },

  methods: {
    connectionStatusClass(status) {
      return (
        {
          success: "bg-success/20 text-success",
          error: "bg-error/20 text-error",
        }[status] || "bg-error/20 text-error"
      );
    },

    errorStatusClass(status) {
      return (
        {
          success: "",
          warning: "text-warning",
          error: "text-error",
        }[status] || ""
      );
    },
  },
};
</script>

<style>
@keyframes pulse-scale {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
}

.animate-pulse-scale {
  animation: pulse-scale 2s cubic-bezier(0.1, 0, 0.8, 1) infinite;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  z-index: 9999 !important;
}
</style>
