import { ref, computed } from "vue";

const messages = ref([]);
let nextId = 0;

export function useToast() {
  const addMessage = (text) => {
    const id = nextId++;
    messages.value.unshift({ id, text, order: messages.value.length });
    setTimeout(() => removeMessage(id), 5000);
  };

  const removeMessage = (id) => {
    const index = messages.value.findIndex((m) => m.id === id);
    if (index > -1) {
      messages.value.splice(index, 1);
    }
  };

  const orderedMessages = computed(() =>
    messages.value.map((message, index) => ({
      ...message,
      order: messages.value.length - index,
    }))
  );

  return {
    messages: orderedMessages,
    addMessage,
    removeMessage,
  };
}
