<template>
  <div>
    <NotificationComponent :message="notificationMessage" />

    <!-- Current Bot Section -->
    <div class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Current Bot</h2>

      <div
        v-if="currentBotLoading"
        class="text-center py-4"
      >
        Loading bot...
      </div>
      <div
        v-else-if="currentBotError"
        class="text-center py-4 text-red-500"
      >
        {{ currentBotError }}
      </div>
      <div
        v-else-if="!currentBot"
        class="bg-base-200 rounded-lg px-4 py-4 text-gray-500"
      >
        No bot currently running on this API key
      </div>
      <div
        v-else
        class="bg-base-200 rounded-lg"
      >
        <div class="flex items-center px-6 py-4">
          <!-- Status and Name -->
          <div class="flex items-center gap-2 w-[250px]">
            <BotStatusIndicator
              :bot="currentBot"
              :bot-health="currentBot.health"
              :is-running="isPodRunning(currentBot.id)"
              class="shrink-0"
            />
            <div class="min-w-0">
              <router-link
                :to="{
                  name: 'AdminBotOverview',
                  params: { id: currentBot.id },
                }"
                class="block text-sm truncate"
              >
                #{{ currentBot.id }} {{ currentBot.name }}
              </router-link>
              <div class="text-xs text-gray-500 truncate">
                <CurrencyDisplay
                  :currency="currentBot.quote_currency"
                  :value="currentBot.range.top"
                />
                -
                <CurrencyDisplay
                  :currency="currentBot.quote_currency"
                  :value="currentBot.range.bottom"
                  :hide-currency="true"
                />
                ({{ formatPercent(currentBot.range.pct) }})
              </div>
            </div>
          </div>

          <div class="w-[140px] flex flex-col">
            <div class="text-xs text-gray-500 mb-1">Base Equity</div>
            <div class="text-sm">
              <CurrencyDisplay
                :currency="currentBot.quote_currency"
                :value="Number(currentBot.adjusted_base_equity)"
                :hide-if-zero="false"
                empty-value="—"
              />
            </div>
          </div>

          <div class="w-[140px] flex flex-col">
            <div class="text-xs text-gray-500 mb-1">Current Equity</div>
            <div class="text-sm">
              <CurrencyDisplay
                :currency="currentBot.settlement_currency"
                :value="calculateConsolidatedWalletEquity(currentBot)"
                :hide-if-zero="false"
                empty-value="—"
              />
            </div>
          </div>

          <div class="w-[100px] flex flex-col">
            <div class="text-xs text-gray-500 mb-1">APY</div>
            <div class="text-sm">
              <span>{{
                currentBot.apy ? formatPercent(currentBot.apy) : "—"
              }}</span>
            </div>
          </div>

          <div class="w-[100px] flex flex-col">
            <div class="text-xs text-gray-500 mb-1">ROI</div>
            <div class="text-sm">
              <span>{{
                currentBot.roi ? formatPercent(currentBot.roi) : "—"
              }}</span>
            </div>
          </div>

          <div class="flex-grow"></div>

          <!-- Actions -->
          <div class="dropdown dropdown-end">
            <label
              tabindex="0"
              class="btn btn-ghost btn-xs btn-square"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                />
              </svg>
            </label>
            <ul
              tabindex="0"
              class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-32 z-50"
            >
              <li>
                <button
                  class="flex items-center px-4 py-2 hover:bg-base-200"
                  @click="
                    handleBotAction({
                      action: 'edit',
                      bot: { bot_id: currentBot.id },
                    })
                  "
                >
                  <PencilIcon class="h-5 w-5 mr-2" />
                  Edit
                </button>
              </li>
              <li>
                <button
                  class="flex items-center px-4 py-2 hover:bg-base-200"
                  @click="
                    handleBotAction({
                      action: 'copy',
                      bot: { bot_id: currentBot.id },
                    })
                  "
                >
                  <DocumentDuplicateIcon class="h-5 w-5 mr-2" />
                  Copy
                </button>
              </li>
              <li>
                <button
                  class="flex items-center px-4 py-2 hover:bg-base-200"
                  @click="
                    handleBotAction({
                      action: 'toggle',
                      bot: {
                        bot_id: currentBot.id,
                        event:
                          currentBot.status === 'active' ? 'start' : 'stop',
                      },
                    })
                  "
                >
                  <PlayIcon
                    v-if="currentBot.status !== 'active'"
                    class="h-5 w-5 mr-2 text-success"
                  />
                  <StopIcon
                    v-else
                    class="h-5 w-5 mr-2 text-error"
                  />
                  <span
                    :class="
                      currentBot.status === 'active'
                        ? 'text-error'
                        : 'text-success'
                    "
                  >
                    {{ currentBot.status === "active" ? "Stop" : "Start" }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Bot History Section -->
    <div class="mt-8">
      <h2 class="text-2xl font-semibold mb-4">Bot History</h2>

      <ResponsiveTable
        :columns="columns"
        :data="botHistory"
        :loading="botHistoryLoading"
      >
        <!-- Timestamp column -->
        <template #timestamp="{ row }">
          {{ formatDatetime(row.timestamp) }}
        </template>

        <!-- Event column -->
        <template #event="{ row }">
          <span :class="row.event === 'start' ? 'text-success' : 'text-error'">
            {{ formatEvent(row.event) }}
          </span>
        </template>

        <!-- Bot column -->
        <template #bot="{ row }">
          <div class="flex flex-col">
            <router-link
              :to="{ name: 'AdminBotOverview', params: { id: row.bot_id } }"
              class="link link-hover font-medium"
            >
              {{ row.bot_name }}
            </router-link>
            <span class="text-sm text-gray-500">{{ row.symbol }}</span>
          </div>
        </template>

        <!-- Actor column -->
        <template #actor="{ row }">
          <span>{{ row.actor }}</span>
        </template>

        <!-- Actions column -->
        <template #actions="{ row }">
          <div class="dropdown dropdown-end">
            <label
              tabindex="0"
              class="btn btn-ghost btn-xs btn-square"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                />
              </svg>
            </label>
            <ul
              tabindex="0"
              class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-32 z-50"
            >
              <li>
                <button
                  class="flex items-center px-4 py-2 hover:bg-base-200"
                  @click="handleBotAction({ action: 'edit', bot: row })"
                >
                  <PencilIcon class="h-5 w-5 mr-2" />
                  Edit
                </button>
              </li>
              <li>
                <button
                  class="flex items-center px-4 py-2 hover:bg-base-200"
                  @click="
                    handleBotAction({
                      action: 'copy',
                      bot: { bot_id: row.bot_id || row.id },
                    })
                  "
                >
                  <DocumentDuplicateIcon class="h-5 w-5 mr-2" />
                  Copy
                </button>
              </li>
              <li>
                <button
                  v-if="isBotActive(row.bot_id) || !hasActiveBot"
                  class="flex items-center px-4 py-2 hover:bg-base-200"
                  @click="handleBotAction({ action: 'toggle', bot: row })"
                >
                  <PlayIcon
                    v-if="!isBotActive(row.bot_id)"
                    class="h-5 w-5 mr-2"
                  />
                  <StopIcon
                    v-else
                    class="h-5 w-5 mr-2"
                  />
                  {{ isBotActive(row.bot_id) ? "Stop" : "Start" }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </ResponsiveTable>

      <!-- Error message if needed -->
      <div
        v-if="botHistoryError"
        class="text-red-500 py-4 text-center"
      >
        {{ botHistoryError }}
      </div>

      <!-- Pagination -->
      <PaginationComponent
        v-if="totalPages > 1"
        :current-page="currentPage"
        :total-pages="totalPages"
        class="mt-4 flex justify-center"
        @page-change="changePage"
      />
    </div>

    <BotModal
      ref="botModal"
      :show="showBotModal"
      :account-id="apiKey?.account"
      :api-keys="[apiKey]"
      :bot-to-edit="botToEdit"
      @close="closeBotModal"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import ResponsiveTable from "@/components/ResponsiveTable.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import BotModal from "@/components/modals/BotModal.vue";
import NotificationComponent from "@/components/NotificationComponent.vue";
import BotStatusIndicator from "@/components/BotStatusIndicator.vue";
import CurrencyDisplay from "@/components/CurrencyDisplay.vue";
import api from "@/api/axios";
import { formatDatetime, formatPercent } from "@/utils/formatting";
import {
  PencilIcon,
  PlayIcon,
  StopIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/24/solid";
import { ref, onMounted, watch, computed } from "vue";

export default {
  name: "BotLogTab",
  components: {
    ResponsiveTable,
    PaginationComponent,
    BotModal,
    NotificationComponent,
    BotStatusIndicator,
    CurrencyDisplay,
    PencilIcon,
    PlayIcon,
    StopIcon,
    DocumentDuplicateIcon,
  },
  props: {
    apiKey: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const notificationMessage = ref("");
    const currentBot = ref(null);
    const currentBotLoading = ref(false);
    const currentBotError = ref(null);
    const botHistory = ref([]);
    const botHistoryLoading = ref(false);
    const botHistoryError = ref(null);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const pageSize = ref(20);
    const showBotModal = ref(false);
    const botToEdit = ref(null);
    const runningPods = ref([]);

    const botColumns = [
      { key: "status", header: "" },
      { key: "name_and_range", header: "Bot", title: true },
      { key: "period_profit", header: "Period P/L" },
      { key: "total_profit", header: "Total P/L", primary: true },
      { key: "adjusted_base_equity", header: "Base Equity" },
      { key: "equity", header: "Current Equity", primary: true },
      { key: "apy", header: "APY", primary: true },
      { key: "roi", header: "ROI", primary: true },
      { key: "actions", header: "", sortable: false },
    ];

    const columns = [
      { key: "bot", header: "Bot", title: true },
      { key: "event", header: "Event" },
      { key: "actor", header: "User" },
      { key: "timestamp", header: "Time" },
      { key: "actions", header: "", sortable: false },
    ];

    const isPodRunning = (botId) => {
      return runningPods.value.some(
        (pod) =>
          pod.name === `quantm-nautilus-bot-${botId}` &&
          pod.status === "Running"
      );
    };

    const calculateConsolidatedWalletEquity = (bot) => {
      if (!bot.equity) return 0;
      return Object.values(bot.equity).reduce((sum, value) => sum + value, 0);
    };

    const formatEvent = (event) => {
      return event.charAt(0).toUpperCase() + event.slice(1);
    };

    const isActive = (row) => {
      return row.event === "start";
    };

    const isBotActive = (botId) => {
      return (
        currentBot.value?.id === botId && currentBot.value?.status === "active"
      );
    };

    const hasActiveBot = computed(() => {
      return currentBot.value !== null && currentBot.value.status === "active";
    });

    const showNotification = (message) => {
      notificationMessage.value = message;
    };

    const editBot = async (botId) => {
      try {
        const response = await api.get(`/api/bots/${botId}/`);
        botToEdit.value = response.data;
        showBotModal.value = true;
      } catch (error) {
        console.error("Error fetching bot details:", error);
        showNotification("Error loading bot details");
      }
    };

    const copyBot = async (botId) => {
      try {
        const response = await api.get(`/api/bots/${botId}/`);
        const botData = response.data;

        const newBot = {
          ...botData,
          id: null,
          name: `${botData.name} (Copy)`,
          status: "new",
          auto_start: false,
        };

        botToEdit.value = { ...newBot, isNewBot: true };
        showBotModal.value = true;
      } catch (error) {
        console.error("Error fetching bot details for copying:", error);
        showNotification("Error loading bot details");
      }
    };

    const startBot = async (botId) => {
      try {
        const response = await api.post(`/api/bots/${botId}/start/`);
        if (response.status === 200) {
          showNotification("Bot successfully started");
          await fetchBotHistory();
          await fetchCurrentBot();
          await fetchRunningPods();
        }
      } catch (error) {
        console.error("Error starting bot:", error);
        showNotification("Error starting bot");
      }
    };

    const stopBot = async (botId) => {
      try {
        const response = await api.post(`/api/bots/${botId}/stop/`);
        if (response.status === 200) {
          showNotification("Bot successfully stopped");
          await fetchBotHistory();
          await fetchCurrentBot();
          await fetchRunningPods();
        }
      } catch (error) {
        console.error("Error stopping bot:", error);
        if (error.response?.data?.detail?.includes("API key is invalid")) {
          showNotification(
            "Cannot stop bot: Invalid API keys. Please update your API keys and try again."
          );
        } else {
          showNotification(
            error.response?.data?.message || "Error stopping bot"
          );
        }
      }
    };

    const handleBotAction = async ({ action, bot }) => {
      if (action === "edit") {
        await editBot(bot.bot_id || bot.id);
      } else if (action === "copy") {
        await copyBot(bot.bot_id || bot.id);
      } else if (action === "toggle") {
        const botId = bot.bot_id || bot.id;
        if (isBotActive(botId)) {
          await stopBot(botId);
        } else {
          await startBot(botId);
        }
      }
    };

    const closeBotModal = () => {
      showBotModal.value = false;
      botToEdit.value = null;
    };

    const handleSubmit = async (formData) => {
      try {
        if (botToEdit.value && !botToEdit.value.isNewBot) {
          await api.put(`/api/bots/${botToEdit.value.id}/`, formData);
          showNotification("Bot successfully updated");
        } else {
          await api.post("/api/bots/", formData);
          showNotification("Bot successfully created");
        }
        closeBotModal();
        await fetchBotHistory();
        await fetchCurrentBot();
      } catch (error) {
        console.error("Error submitting bot form:", error);
        throw error;
      }
    };

    const changePage = (newPage) => {
      currentPage.value = newPage;
      fetchBotHistory();
    };

    const fetchCurrentBot = async () => {
      if (!props.apiKey?.id) return;

      currentBotLoading.value = true;
      currentBotError.value = null;

      try {
        const response = await api.get(
          `/api/api-keys/${props.apiKey.id}/bots/`,
          {
            params: { status: "active" },
          }
        );
        currentBot.value = response.data[0] || null;
        if (currentBot.value) {
          currentBot.value = {
            ...currentBot.value,
            period_profit: currentBot.value.period_profit || {},
            total_profit: currentBot.value.total_profit || {},
            investment: currentBot.value.investment || {},
            equity: currentBot.value.equity || {},
            range: currentBot.value.range || { top: 0, bottom: 0, pct: 0 },
          };
        }
      } catch (err) {
        console.error("Error fetching current bot:", err);
        currentBotError.value = "Failed to load current bot";
      } finally {
        currentBotLoading.value = false;
      }
    };

    const fetchRunningPods = async () => {
      try {
        const response = await api.get("/api/bots/running_pods/");
        runningPods.value = response.data;
      } catch (error) {
        console.error("Error fetching running pods:", error);
      }
    };

    const fetchBotHistory = async () => {
      if (!props.apiKey?.id) {
        console.log("API key data not available yet");
        return;
      }

      // Prevent duplicate fetches while loading
      if (botHistoryLoading.value) {
        console.log("Already fetching bot history");
        return;
      }

      botHistoryLoading.value = true;
      botHistoryError.value = null;

      try {
        const response = await api.get(
          `/api/api-keys/${props.apiKey.id}/bot_history/`,
          {
            params: {
              page: currentPage.value,
              page_size: pageSize.value,
            },
          }
        );

        if (response.data.results) {
          botHistory.value = response.data.results;
          totalPages.value = Math.ceil(response.data.count / pageSize.value);
        } else {
          // Handle non-paginated response
          botHistory.value = response.data;
          totalPages.value = 1;
        }
      } catch (err) {
        console.error("Error fetching bot history:", err);
        botHistoryError.value =
          "Failed to fetch bot history. Please try again later.";
      } finally {
        botHistoryLoading.value = false;
      }
    };

    // Watch for API key changes
    watch(
      () => props.apiKey?.id,
      async (newId) => {
        if (newId) {
          try {
            await fetchCurrentBot();
            await fetchBotHistory();
            await fetchRunningPods();
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      },
      { immediate: true }
    );

    // Watch for page changes
    watch(
      () => currentPage.value,
      async () => {
        await fetchBotHistory();
      }
    );

    onMounted(() => {
      if (props.apiKey?.id) {
        fetchCurrentBot();
        fetchRunningPods();
        fetchBotHistory();
      }
    });

    return {
      notificationMessage,
      currentBot,
      currentBotLoading,
      currentBotError,
      botHistory,
      botHistoryLoading,
      botHistoryError,
      currentPage,
      totalPages,
      pageSize,
      showBotModal,
      botToEdit,
      runningPods,
      botColumns,
      columns,
      isPodRunning,
      calculateConsolidatedWalletEquity,
      formatEvent,
      isActive,
      isBotActive,
      hasActiveBot,
      showNotification,
      handleBotAction,
      closeBotModal,
      handleSubmit,
      changePage,
      formatDatetime,
      formatPercent,
    };
  },
};
</script>

<style scoped>
.responsive-table {
  @apply w-full;
}

@media (max-width: 768px) {
  .responsive-table td {
    @apply block;
  }
}
</style>
