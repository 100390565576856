<template>
  <div class="chart-tab">
    <div class="flex justify-between items-center gap-2 mb-4">
      <div class="flex items-center gap-4">
        <h3 class="text-lg font-semibold">{{ backtest.name }}</h3>
      </div>
      <div class="flex gap-2">
        <button
          class="btn btn-sm"
          :class="{ 'ma-active': showMA }"
          @click="toggleMA"
          :disabled="isLoading"
        >
          MA(20)
        </button>
        <button
          class="btn btn-sm"
          :class="{ 'vwap-active': showVWAP }"
          @click="toggleVWAP"
          :disabled="isLoading"
        >
          VWAP
        </button>
        <div class="join">
          <button
            v-for="tf in timeframes"
            :key="tf.value"
            class="join-item btn btn-sm"
            :class="{ 'btn-active': selectedTimeframe === tf.value }"
            @click="changeTimeframe(tf.value)"
            :disabled="isLoading"
          >
            {{ tf.label }}
          </button>
        </div>
      </div>
    </div>

    <!-- Loading Progress -->
    <div
      v-if="isLoading"
      class="loading-overlay"
    >
      <div class="loading-content">
        <div class="loading-spinner"></div>
        <div class="loading-text">Loading Chart Data...</div>
      </div>
    </div>

    <div
      ref="chartContainer"
      class="chart-container bg-card rounded-lg"
    ></div>
  </div>
</template>

<script>
import {
  createChart,
  CandlestickSeries,
  LineSeries,
  createSeriesMarkers,
} from "lightweight-charts";
import { onMounted, ref, onBeforeUnmount, watch } from "vue";
import api from "@/api/axios";

export default {
  name: "ChartTab",
  props: {
    backtest: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const chartContainer = ref(null);
    const chart = ref(null);
    const candleSeries = ref(null);
    const maSeries = ref(null);
    const vwapSeries = ref(null);
    const vwapUpperBand = ref(null);
    const vwapLowerBand = ref(null);
    const seriesMarkers = ref(null);
    const crosshairEnabled = ref(false);
    const priceInfo = ref(null);
    const selectedTimeframe = ref("1d");
    const visibleRange = ref(null);
    const isLoading = ref(false);
    const lastUpdate = ref(Date.now());
    const isInitialLoad = ref(true);
    const showMA = ref(false);
    const showVWAP = ref(false);
    const isRestoringPosition = ref(false);
    const toolTip = ref(null);

    const timeframes = [
      { label: "1m", value: "1m" },
      { label: "5m", value: "5m" },
      { label: "15m", value: "15m" },
      { label: "1h", value: "1h" },
      { label: "4h", value: "4h" },
      { label: "1d", value: "1d" },
    ];

    // Zoom limits for different timeframes
    const zoomLimits = Object.fromEntries(
      timeframes.map((tf) => [
        tf.value,
        { maxBars: 600, minSpacing: 3, maxSpacing: 15 },
      ])
    );

    // Define chunk size for data loading (number of candles per API call)
    const CHUNK_SIZE = 499;

    // Helper function to validate chunk continuity
    const validateChunkContinuity = (existingData, newData, timeframe) => {
      if (!existingData.length || !newData.length) return true;

      // Get the time duration for the timeframe
      const timeframeDurations = {
        "1m": 60,
        "5m": 300,
        "15m": 900,
        "1h": 3600,
        "4h": 14400,
        "1d": 86400,
      };
      const duration = timeframeDurations[timeframe];

      // Sort both arrays by time to ensure proper comparison
      const sortedExisting = [...existingData].sort((a, b) => a.time - b.time);
      const sortedNew = [...newData].sort((a, b) => a.time - b.time);

      // Get the earliest and latest times from existing data
      const existingEarliestTime = sortedExisting[0].time;
      const existingLatestTime = sortedExisting[sortedExisting.length - 1].time;

      // Get the earliest and latest times from new data
      const newEarliestTime = sortedNew[0].time;
      const newLatestTime = sortedNew[sortedNew.length - 1].time;

      // Determine if new data is before or after existing data
      const isNewDataBefore = newLatestTime <= existingEarliestTime;
      const isNewDataAfter = newEarliestTime >= existingLatestTime;

      // For debugging
      console.log("Validating chunk continuity:", {
        timeframe,
        existingRange: {
          from: new Date(existingEarliestTime * 1000).toISOString(),
          to: new Date(existingLatestTime * 1000).toISOString(),
        },
        newDataRange: {
          from: new Date(newEarliestTime * 1000).toISOString(),
          to: new Date(newLatestTime * 1000).toISOString(),
        },
      });

      if (isNewDataBefore) {
        // When loading backwards (e.g., getting candle 499 when we have 501)
        // Check if any candle in the new chunk is the immediate predecessor
        // or close enough to be considered continuous
        const hasValidPredecessor = sortedNew.some((candle) => {
          const timeDiff = existingEarliestTime - candle.time;
          return timeDiff > 0 && timeDiff <= duration * 2; // Allow up to 2 intervals gap
        });

        if (!hasValidPredecessor) {
          console.warn(
            `Gap in backward data: Existing earliest ${new Date(
              existingEarliestTime * 1000
            ).toISOString()}, ` +
              `New latest ${new Date(newLatestTime * 1000).toISOString()}`
          );
        }
        return hasValidPredecessor;
      } else if (isNewDataAfter) {
        // When loading forwards (e.g., getting candle 1001 when we have 1000)
        // Check if any candle in the new chunk is the immediate successor
        // or close enough to be considered continuous
        const hasValidSuccessor = sortedNew.some((candle) => {
          const timeDiff = candle.time - existingLatestTime;
          return timeDiff > 0 && timeDiff <= duration * 2; // Allow up to 2 intervals gap
        });

        if (!hasValidSuccessor) {
          console.warn(
            `Gap in forward data: Existing latest ${new Date(
              existingLatestTime * 1000
            ).toISOString()}, ` +
              `New earliest ${new Date(newEarliestTime * 1000).toISOString()}`
          );
        }
        return hasValidSuccessor;
      }

      // If data overlaps or fills a gap, check if it contains any new candles
      const existingTimes = new Set(
        sortedExisting.map((candle) => candle.time)
      );
      const hasNewCandles = sortedNew.some(
        (candle) => !existingTimes.has(candle.time)
      );

      if (!hasNewCandles) {
        console.log("Overlapping chunk contains no new candles");
      }
      return hasNewCandles;
    };

    const createOrderMarkers = () => {
      if (!props.backtest?.order_fills?.length || !candleSeries.value) return;

      // Create markers primitive only once if it doesn't exist
      if (!seriesMarkers.value) {
        seriesMarkers.value = createSeriesMarkers(candleSeries.value, []);
      }

      const currentData = candleSeries.value.data();
      if (!currentData || currentData.length === 0) return;

      // Get the visible time range instead of loaded range
      const visibleRange = chart.value.timeScale().getVisibleRange();
      if (!visibleRange) return;

      // Only process markers within the visible range plus some padding
      const timeframeDurations = {
        "1m": 60,
        "5m": 300,
        "15m": 900,
        "1h": 3600,
        "4h": 14400,
        "1d": 86400,
      };
      const duration = timeframeDurations[selectedTimeframe.value];
      const PADDING_BARS = 50; // Show markers for 50 bars before and after visible range
      const paddingTime = duration * PADDING_BARS;

      const visibleTimeRange = {
        from: visibleRange.from - paddingTime,
        to: visibleRange.to + paddingTime,
      };

      // Only create markers for timestamps within visible range
      const markers = props.backtest.order_fills
        .filter((order) => {
          const timestamp = Math.floor(
            new Date(order.ts_last).getTime() / 1000
          );
          return (
            timestamp >= visibleTimeRange.from &&
            timestamp <= visibleTimeRange.to
          );
        })
        .map((order) => {
          const timestamp = Math.floor(
            new Date(order.ts_last).getTime() / 1000
          );
          const isBuy = order.side.toLowerCase() === "buy";
          const price = parseFloat(order.avg_px);

          return {
            time: timestamp,
            position: isBuy ? "belowBar" : "aboveBar",
            color: isBuy ? "#2196F3" : "#e91e63",
            shape: isBuy ? "arrowUp" : "arrowDown",
            text: `${isBuy ? "Buy" : "Sell"} @ $${formatPrice(price)}`,
          };
        });

      // Update markers if we have any in range
      if (markers.length > 0) {
        seriesMarkers.value.setMarkers(markers);
      }
    };

    const resetMarkers = () => {
      if (seriesMarkers.value) {
        seriesMarkers.value.setMarkers([]);
      }
    };

    // Add a debounced version of createOrderMarkers
    const debouncedCreateOrderMarkers = (() => {
      let timeoutId;
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          createOrderMarkers();
        }, 100); // 100ms debounce
      };
    })();

    const handleResize = () => {
      if (chart.value && chartContainer.value) {
        chart.value.applyOptions({
          width: chartContainer.value.clientWidth,
        });
      }
    };

    const formatPrice = (price) => {
      if (typeof price !== "number") return "";
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(price);
    };

    const fitContent = () => {
      if (chart.value) {
        chart.value.timeScale().fitContent();
      }
    };

    const toggleCrosshair = () => {
      crosshairEnabled.value = !crosshairEnabled.value;
      if (chart.value) {
        chart.value.applyOptions({
          crosshair: {
            mode: crosshairEnabled.value ? 1 : 0,
          },
        });
      }
    };

    const toggleMA = () => {
      showMA.value = !showMA.value;
      if (maSeries.value) {
        maSeries.value.applyOptions({
          visible: showMA.value,
        });
      }
    };

    const calculateVWAP = (data) => {
      let cumulativeTPV = 0;
      let cumulativeVolume = 0;
      let sumSquaredDiff = 0;
      const vwapData = [];
      const upperBandData = [];
      const lowerBandData = [];
      let currentDay = null;

      data.forEach((candle, index) => {
        // Get the day from the timestamp
        const candleDate = new Date(candle.time * 1000);
        const day = candleDate.toISOString().split("T")[0];

        // Reset calculations at the start of each day
        if (day !== currentDay) {
          currentDay = day;
          cumulativeTPV = 0;
          cumulativeVolume = 0;
          sumSquaredDiff = 0;
        }

        // Calculate typical price properly
        const typicalPrice = (candle.high + candle.low + candle.close) / 3;
        const volume = candle.volume || 1;

        cumulativeTPV += typicalPrice * volume;
        cumulativeVolume += volume;

        const vwap = cumulativeTPV / cumulativeVolume;
        vwapData.push({ time: candle.time, value: vwap });

        // Calculate standard deviation for bands
        if (index > 0) {
          sumSquaredDiff += Math.pow(typicalPrice - vwap, 2) * volume;
          const standardDev = Math.sqrt(sumSquaredDiff / cumulativeVolume);

          upperBandData.push({
            time: candle.time,
            value: vwap + 2 * standardDev, // Back to 2 standard deviations for better range
          });
          lowerBandData.push({
            time: candle.time,
            value: vwap - 2 * standardDev, // Back to 2 standard deviations for better range
          });
        } else {
          upperBandData.push({ time: candle.time, value: vwap });
          lowerBandData.push({ time: candle.time, value: vwap });
        }
      });

      return { vwapData, upperBandData, lowerBandData };
    };

    const toggleVWAP = () => {
      showVWAP.value = !showVWAP.value;
      if (vwapSeries.value) {
        vwapSeries.value.applyOptions({ visible: showVWAP.value });
      }
      if (vwapUpperBand.value) {
        vwapUpperBand.value.applyOptions({ visible: showVWAP.value });
      }
      if (vwapLowerBand.value) {
        vwapLowerBand.value.applyOptions({ visible: showVWAP.value });
      }
    };

    async function updateChartData() {
      try {
        if (!props.backtest?.run_id || isLoading.value) {
          return;
        }

        if (isInitialLoad.value) {
          isLoading.value = true;
          resetMarkers();
        }

        // Get current data to determine request range
        const currentData = candleSeries.value?.data() || [];
        let requestFrom = visibleRange.value.from;
        let requestTo = visibleRange.value.to;

        // If we have existing data, adjust the request range to only get new data
        if (currentData.length > 0) {
          const loadedFrom = currentData[0].time;
          const loadedTo = currentData[currentData.length - 1].time;

          // Calculate the duration of one chunk based on timeframe
          const timeframeDurations = {
            "1m": 60,
            "5m": 300,
            "15m": 900,
            "1h": 3600,
            "4h": 14400,
            "1d": 86400,
          };
          const duration = timeframeDurations[selectedTimeframe.value];

          // Request a smaller chunk size when loading backwards to ensure continuity
          const BACKWARD_CHUNK_SIZE = 100; // Smaller chunk for backwards loading

          // For 1-minute data, ensure we're loading the full visible range
          if (selectedTimeframe.value === "1m") {
            // If loading earlier data
            if (requestFrom < loadedFrom) {
              requestTo = loadedFrom; // Load up to where we already have data
              requestFrom = requestTo - duration * BACKWARD_CHUNK_SIZE;
            }
            // If loading later data
            else if (requestTo > loadedTo) {
              requestFrom = loadedTo; // Load from where we left off
              requestTo = requestFrom + duration * CHUNK_SIZE;
            }
          } else {
            // Original chunk logic for other timeframes
            if (requestFrom < loadedFrom) {
              const backwardChunkDuration = duration * BACKWARD_CHUNK_SIZE;
              requestFrom = Math.max(
                requestFrom,
                loadedFrom - backwardChunkDuration
              );
              requestTo = loadedFrom + duration;
            } else if (requestTo > loadedTo) {
              const forwardChunkDuration = duration * CHUNK_SIZE;
              requestFrom = loadedTo - duration;
              requestTo = Math.min(requestTo, loadedTo + forwardChunkDuration);
            }
          }
        }

        const response = await api.get(
          `/api/backtests/${props.backtest.run_id}/chart_data/`,
          {
            params: {
              timeframe: selectedTimeframe.value,
              from: new Date(requestFrom * 1000).toISOString(),
              to: new Date(requestTo * 1000).toISOString(),
              limit: CHUNK_SIZE,
            },
          }
        );

        if (response?.data?.candles?.length) {
          const newCandles = response.data.candles;

          if (candleSeries.value) {
            const existingData = candleSeries.value.data() || [];

            // Validate continuity between existing data and new candles
            const isValidChunk = validateChunkContinuity(
              existingData,
              newCandles,
              selectedTimeframe.value
            );

            if (!isValidChunk) {
              console.warn(
                "Received non-continuous data chunk, skipping merge"
              );
              return;
            }

            // Create a map of existing data for quick lookup
            const existingDataMap = new Map(
              existingData.map((candle) => [candle.time, candle])
            );

            // Merge new candles with existing data, avoiding duplicates
            newCandles.forEach((candle) => {
              if (!existingDataMap.has(candle.time)) {
                existingDataMap.set(candle.time, candle);
              }
            });

            // Convert back to array and sort
            const mergedData = Array.from(existingDataMap.values()).sort(
              (a, b) => a.time - b.time
            );

            // Update the series with merged data
            candleSeries.value.setData(mergedData);

            // Calculate and update MA
            if (maSeries.value) {
              const maData = calculateMA(mergedData);
              maSeries.value.setData(maData);
            }

            // Calculate and update VWAP and bands
            if (vwapSeries.value) {
              const { vwapData, upperBandData, lowerBandData } =
                calculateVWAP(mergedData);
              vwapSeries.value.setData(vwapData);
              vwapUpperBand.value.setData(upperBandData);
              vwapLowerBand.value.setData(lowerBandData);
            }

            // Update markers after data is loaded
            createOrderMarkers();
          }
        }
      } catch (error) {
        console.error("Error updating chart:", error);
      } finally {
        isLoading.value = false;
        isInitialLoad.value = false;
      }
    }

    const formatTimeByTimeframe = (timestamp, timeframe, showTime = true) => {
      const date = new Date(timestamp * 1000);
      const options = { timeZone: "UTC" };

      switch (timeframe) {
        case "1m":
        case "5m":
        case "15m":
          return showTime
            ? date.toLocaleString("en-US", {
                ...options,
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })
            : date.toLocaleTimeString("en-US", {
                ...options,
                hour: "numeric",
                minute: "numeric",
              });
        case "1h":
        case "4h":
          return showTime
            ? date.toLocaleString("en-US", {
                ...options,
                month: "short",
                day: "numeric",
                hour: "numeric",
              })
            : date.toLocaleString("en-US", {
                ...options,
                month: "short",
                day: "numeric",
                hour: "numeric",
              });
        case "1d":
          return date.toLocaleDateString("en-US", {
            ...options,
            year: "numeric",
            month: "short",
            day: "numeric",
          });
        default:
          return date.toLocaleString("en-US", options);
      }
    };

    const changeTimeframe = async (timeframe) => {
      // Store the current visible time range before changing timeframe
      const currentTimeRange = chart.value.timeScale().getVisibleRange();

      // Calculate the center point of the current view
      const centerTime = Math.floor(
        (currentTimeRange.from + currentTimeRange.to) / 2
      );

      selectedTimeframe.value = timeframe;
      resetMarkers(); // Reset markers when changing timeframe

      // Get the zoom limits for the new timeframe
      const limits = zoomLimits[timeframe];

      // Calculate the time duration for the zoom limit
      const timeframeDurations = {
        "1m": 60,
        "5m": 300,
        "15m": 900,
        "1h": 3600,
        "4h": 14400,
        "1d": 86400,
      };
      const duration = timeframeDurations[timeframe];

      // Calculate a reasonable initial data fetch range (e.g., 200 bars worth of data)
      const initialDataRange = duration * 200;

      // Set initial data loading range centered on the current view
      visibleRange.value = {
        from: centerTime - initialDataRange,
        to: centerTime + initialDataRange,
      };

      if (chart.value && limits) {
        chart.value.timeScale().applyOptions({
          minBarSpacing: limits.minSpacing,
          maxBarSpacing: limits.maxSpacing,
          maxVisibleLogicalRange: {
            from: 0,
            to: limits.maxBars,
          },
        });
      }

      // Clear existing data
      if (candleSeries.value) {
        candleSeries.value.setData([]);
        if (maSeries.value) maSeries.value.setData([]);
        if (vwapSeries.value) {
          vwapSeries.value.setData([]);
          vwapUpperBand.value?.setData([]);
          vwapLowerBand.value?.setData([]);
        }
      }

      // Set isRestoringPosition to true before updating data
      isRestoringPosition.value = true;
      isInitialLoad.value = true; // Treat this as an initial load to ensure data fetching

      try {
        await updateChartData();

        // After data is loaded, set the view to be centered on our target point
        if (chart.value) {
          // Use requestAnimationFrame to ensure the chart has updated
          requestAnimationFrame(() => {
            const targetRange = {
              from: centerTime - duration * 100, // Show 100 bars worth of data
              to: centerTime + duration * 100,
            };
            chart.value.timeScale().setVisibleRange(targetRange);

            // Use another requestAnimationFrame to ensure position restoration is complete
            requestAnimationFrame(() => {
              isRestoringPosition.value = false;
              isInitialLoad.value = false;
            });
          });
        } else {
          isRestoringPosition.value = false;
          isInitialLoad.value = false;
        }
      } catch (error) {
        console.error("Error updating chart data:", error);
        isRestoringPosition.value = false;
        isInitialLoad.value = false;
      }
    };

    // Get start and end timestamps from backtest or fallback to first/last daily return/order fill
    const getTimeRange = () => {
      let startTimestamp = 0;
      let endTimestamp = 0;

      // First try to get from backtest model
      if (props.backtest?.start_date) {
        startTimestamp = Math.floor(
          new Date(props.backtest.start_date).getTime() / 1000
        );
      }
      if (props.backtest?.end_date) {
        endTimestamp = Math.floor(
          new Date(props.backtest.end_date).getTime() / 1000
        );
      }

      // Then try daily returns if needed
      if (startTimestamp === 0 && props.backtest?.daily_returns?.length > 0) {
        const firstReturn = props.backtest.daily_returns[0];
        const [startYear, startMonth, startDay] = firstReturn.date
          .split("-")
          .map(Number);
        startTimestamp = Math.floor(
          new Date(Date.UTC(startYear, startMonth - 1, startDay)).getTime() /
            1000
        );
      }
      if (endTimestamp === 0 && props.backtest?.daily_returns?.length > 0) {
        const lastReturn =
          props.backtest.daily_returns[props.backtest.daily_returns.length - 1];
        const [endYear, endMonth, endDay] = lastReturn.date
          .split("-")
          .map(Number);
        endTimestamp = Math.floor(
          new Date(
            Date.UTC(endYear, endMonth - 1, endDay, 23, 59, 59)
          ).getTime() / 1000
        );
      }

      // Finally try order fills if still needed
      if (startTimestamp === 0 && props.backtest?.order_fills?.length > 0) {
        const firstOrder = props.backtest.order_fills[0];
        startTimestamp = Math.floor(
          new Date(firstOrder.ts_init).getTime() / 1000
        );
      }
      if (endTimestamp === 0 && props.backtest?.order_fills?.length > 0) {
        const lastOrder =
          props.backtest.order_fills[props.backtest.order_fills.length - 1];
        endTimestamp = Math.floor(new Date(lastOrder.ts_last).getTime() / 1000);
      }

      // If we still don't have an end timestamp, use current time
      if (endTimestamp === 0) {
        endTimestamp = Math.floor(Date.now() / 1000);
      }

      // Ensure the end timestamp is at the end of the day (23:59:59 UTC)
      const endDate = new Date(endTimestamp * 1000);
      endTimestamp = Math.floor(
        new Date(
          Date.UTC(
            endDate.getUTCFullYear(),
            endDate.getUTCMonth(),
            endDate.getUTCDate(),
            23,
            59,
            59
          )
        ).getTime() / 1000
      );

      return { startTimestamp, endTimestamp };
    };

    // Function to calculate moving average
    const calculateMA = (data, length = 20) => {
      const maData = [];

      for (let i = 0; i < data.length; i++) {
        if (i < length - 1) {
          // Add empty point until we have enough data
          maData.push({ time: data[i].time });
        } else {
          // Calculate MA
          let sum = 0;
          for (let j = 0; j < length; j++) {
            sum += data[i - j].close;
          }
          maData.push({
            time: data[i].time,
            value: sum / length,
          });
        }
      }
      return maData;
    };

    onMounted(() => {
      const { startTimestamp, endTimestamp } = getTimeRange();

      // Initialize chart instance
      chart.value = createChart(chartContainer.value, {
        height: 600,
        layout: {
          background: { type: "solid", color: "transparent" },
          textColor: "#9ca3af",
          fontSize: 12,
        },
        grid: {
          vertLines: { color: "rgba(107, 114, 128, 0.2)" },
          horzLines: { color: "rgba(107, 114, 128, 0.2)" },
        },
        crosshair: {
          mode: 1,
          vertLine: {
            visible: true,
            labelVisible: false,
            style: 2,
            width: 1,
            color: "rgba(0, 0, 0, 0.4)",
          },
          horzLine: {
            visible: false,
            labelVisible: false,
          },
        },
        rightPriceScale: {
          borderColor: "rgba(107, 114, 128, 0.3)",
          scaleMargins: {
            top: 0.1,
            bottom: 0.1,
          },
          borderVisible: true,
          autoScale: true,
          formatPrice: (price) => {
            return "$" + formatPrice(price);
          },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
          borderColor: "rgba(107, 114, 128, 0.3)",
          borderVisible: true,
          rightOffset: 12,
          barSpacing: 12,
          minBarSpacing: 2,
          maxBarSpacing: 50,
          fixLeftEdge: false,
          fixRightEdge: false,
          rightBarStaysOnScroll: true,
          minVisibleRange: {
            from: startTimestamp,
            to: startTimestamp + 24 * 60 * 60,
          },
          maxVisibleLogicalRange: {
            from: 0,
            to: zoomLimits["1d"].maxBars,
          },
          tickMarkFormatter: (time, tickMarkType) => {
            const showFullDate = tickMarkType <= 2;
            return formatTimeByTimeframe(
              time,
              selectedTimeframe.value,
              !showFullDate
            );
          },
          localization: {
            timeFormatter: (time) => {
              return formatTimeByTimeframe(time, selectedTimeframe.value, true);
            },
            priceFormatter: (price) => {
              return "$" + formatPrice(price);
            },
          },
        },
        handleScroll: true, // Simplified in v5
        handleScale: true, // Simplified in v5
      });

      // Initialize tooltip reference
      const container = chartContainer.value;

      // Create and style the tooltip html element
      toolTip.value = document.createElement("div");
      toolTip.value.style = `
        width: auto;
        position: absolute;
        display: none;
        box-sizing: border-box;
        font-size: 13px;
        text-align: left;
        z-index: 1000;
        pointer-events: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: var(--card);
        color: var(--text);
        padding: 4px 8px;
        border-radius: 4px 0 0 0;
        backdrop-filter: blur(4px);
        border: 1px solid rgba(107, 114, 128, 0.1);
        margin: 0;
        line-height: 1.3;
      `;

      // Wait for chart to be fully initialized
      setTimeout(() => {
        // Find the chart pane (the canvas where the chart is drawn)
        const chartPane = container.querySelector("canvas");
        if (chartPane) {
          const parent = chartPane.parentElement;
          parent.style.position = "relative";
          parent.appendChild(toolTip.value);
          toolTip.value.style.left = "0";
          toolTip.value.style.top = "0";
        } else {
          console.log("Chart pane not found", container.innerHTML);
        }
      }, 100);

      // Store unsubscribe functions
      const unsubscribeFunctions = [];

      // Create candlestick series with enhanced options
      candleSeries.value = chart.value.addSeries(CandlestickSeries, {
        upColor: "#26a69a", // TradingView green
        downColor: "#ef5350", // TradingView red
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
        priceFormat: {
          type: "price",
          precision: 2,
          minMove: 0.01,
          prefix: "$",
        },
      });

      // Create MA line series
      maSeries.value = chart.value.addSeries(LineSeries, {
        color: "#2962FF",
        lineWidth: 1,
        visible: showMA.value,
        priceFormat: {
          type: "price",
          precision: 2,
          minMove: 0.01,
          prefix: "$",
        },
      });

      // Create VWAP series
      vwapSeries.value = chart.value.addSeries(LineSeries, {
        color: "#9B59B6",
        lineWidth: 2,
        visible: showVWAP.value,
        priceFormat: {
          type: "price",
          precision: 2,
          minMove: 0.01,
          prefix: "$",
        },
      });

      // Create VWAP bands
      vwapUpperBand.value = chart.value.addSeries(LineSeries, {
        color: "rgba(155, 89, 182, 0.3)",
        lineWidth: 1,
        visible: showVWAP.value,
        priceFormat: {
          type: "price",
          precision: 2,
          minMove: 0.01,
          prefix: "$",
        },
      });

      vwapLowerBand.value = chart.value.addSeries(LineSeries, {
        color: "rgba(155, 89, 182, 0.3)",
        lineWidth: 1,
        visible: showVWAP.value,
        priceFormat: {
          type: "price",
          precision: 2,
          minMove: 0.01,
          prefix: "$",
        },
      });

      // Update markers initially
      createOrderMarkers();

      // Function to update candlestick appearance based on bar spacing
      const updateCandlestickAppearance = () => {
        candleSeries.value.applyOptions({
          upColor: "#26a69a",
          downColor: "#ef5350",
          borderVisible: true,
          wickVisible: true,
          borderColor: "rgba(107, 114, 128, 0.3)",
          wickColor: "rgba(156, 163, 175, 0.5)",
          borderUpColor: "#26a69a",
          borderDownColor: "#ef5350",
          wickUpColor: "#26a69a",
          wickDownColor: "#ef5350",
        });
      };

      // Update appearance initially
      updateCandlestickAppearance();

      // Subscribe to visible range changes
      unsubscribeFunctions.push(
        chart.value.timeScale().subscribeVisibleTimeRangeChange((timeRange) => {
          if (
            !timeRange ||
            !chart.value ||
            isLoading.value ||
            isRestoringPosition.value
          )
            return;

          try {
            const { startTimestamp, endTimestamp } = getTimeRange();
            const now = Date.now();
            if (now - lastUpdate.value < 500) return;

            // Get the actual loaded candles
            const currentData = candleSeries.value?.data() || [];
            if (currentData.length === 0) {
              visibleRange.value = {
                from: startTimestamp,
                to: endTimestamp,
              };
              lastUpdate.value = now;
              updateChartData();
              return;
            }

            // Find the actual time range of loaded candles
            const loadedFrom = currentData[0].time;
            const loadedTo = currentData[currentData.length - 1].time;

            // Calculate buffer size based on timeframe
            const timeframeDurations = {
              "1m": 60,
              "5m": 300,
              "15m": 900,
              "1h": 3600,
              "4h": 14400,
              "1d": 86400,
            };
            const duration = timeframeDurations[selectedTimeframe.value];
            const BUFFER_CANDLES = 50;
            const bufferThreshold = duration * BUFFER_CANDLES;

            // Check if we need to load more data based on the chart's logical range
            const needsEarlierData =
              timeRange.from < loadedFrom + bufferThreshold;
            const needsLaterData = timeRange.to > loadedTo - bufferThreshold;

            if (needsEarlierData || needsLaterData) {
              // Store the current visible logical range before loading new data
              const currentVisibleLogicalRange = chart.value
                .timeScale()
                .getVisibleLogicalRange();

              // Set the data loading range based on loaded data boundaries, not visible range
              visibleRange.value = {
                from: needsEarlierData
                  ? Math.max(startTimestamp, loadedFrom - duration * CHUNK_SIZE)
                  : loadedFrom,
                to: needsLaterData
                  ? Math.min(endTimestamp, loadedTo + duration * CHUNK_SIZE)
                  : loadedTo,
              };

              lastUpdate.value = now;
              isRestoringPosition.value = true;

              // Update data and restore position in one place
              updateChartData().then(() => {
                if (currentVisibleLogicalRange && chart.value) {
                  // Use requestAnimationFrame to ensure the chart has updated before restoring position
                  requestAnimationFrame(() => {
                    chart.value
                      .timeScale()
                      .setVisibleLogicalRange(currentVisibleLogicalRange);

                    // Use requestAnimationFrame again to ensure the position restoration is complete
                    requestAnimationFrame(() => {
                      isRestoringPosition.value = false;
                      // Update markers after position is restored
                      debouncedCreateOrderMarkers();
                    });
                  });
                } else {
                  isRestoringPosition.value = false;
                  // Update markers if no position restoration needed
                  debouncedCreateOrderMarkers();
                }
              });
            } else {
              // Update markers when visible range changes but no data loading needed
              debouncedCreateOrderMarkers();
            }
          } catch (error) {
            console.error("Error in range change handler:", error);
            isRestoringPosition.value = false;
          }
        })
      );

      // Subscribe to crosshair move to update tooltip
      chart.value.subscribeCrosshairMove((param) => {
        if (
          param.point === undefined ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > container.clientWidth ||
          param.point.y < 0 ||
          param.point.y > container.clientHeight
        ) {
          toolTip.value.style.display = "none";
          return;
        }

        // Get the current series data at this timestamp
        const currentData = candleSeries.value.data();
        const dataPoint = currentData.find((d) => d.time === param.time);

        if (!dataPoint) {
          toolTip.value.style.display = "none";
          return;
        }

        const dateStr = formatTimeByTimeframe(
          param.time,
          selectedTimeframe.value,
          true
        );

        toolTip.value.style.display = "block";
        toolTip.value.innerHTML = `
          <div style="font-size: 13px; display: flex; align-items: center; gap: 8px; font-feature-settings: 'tnum' on, 'lnum' on;">
            <span style="color: var(--muted); font-size: 12px;">${dateStr}</span>
            <span style="color: ${
              dataPoint.open >= dataPoint.close
                ? "var(--error)"
                : "var(--success)"
            }; font-feature-settings: 'tnum' on, 'lnum' on;">
              O <span style="color: var(--text); font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; margin-right: 6px; font-weight: 500;">$${formatPrice(
                dataPoint.open
              )}</span>
              H <span style="color: var(--text); font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; margin-right: 6px; font-weight: 500;">$${formatPrice(
                dataPoint.high
              )}</span>
              L <span style="color: var(--text); font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; margin-right: 6px; font-weight: 500;">$${formatPrice(
                dataPoint.low
              )}</span>
              C <span style="color: var(--text); font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; margin-right: ${
                dataPoint.volume ? "6px" : "0"
              }; font-weight: 500;">$${formatPrice(dataPoint.close)}</span>
            </span>
            ${
              dataPoint.volume
                ? `
              <span style="color: var(--muted); font-size: 12px;">
                Vol <span style="color: var(--text); font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; font-weight: 500;">${dataPoint.volume.toLocaleString()}</span>
              </span>
            `
                : ""
            }
          </div>
        `;

        // Update the priceInfo ref for the header display
        priceInfo.value = {
          open: formatPrice(dataPoint.open),
          high: formatPrice(dataPoint.high),
          low: formatPrice(dataPoint.low),
          close: formatPrice(dataPoint.close),
        };
      });

      window.addEventListener("resize", handleResize);

      // Fetch initial data
      if (props.backtest) {
        // Set initial visible range
        visibleRange.value = {
          from: startTimestamp,
          to: endTimestamp,
        };
        updateChartData();
      }

      // Store cleanup functions in a ref for use in onBeforeUnmount
      const cleanup = () => {
        unsubscribeFunctions.forEach((fn) => fn?.());
        if (chart.value) {
          chart.value.remove();
          chart.value = null;
        }
        window.removeEventListener("resize", handleResize);
      };

      onBeforeUnmount(cleanup);
    });

    // Watch for changes to the backtest prop
    watch(
      () => props.backtest,
      (newBacktest) => {
        if (newBacktest && candleSeries.value) {
          resetMarkers(); // Reset markers when backtest changes
          updateChartData();
        }
      }
    );

    return {
      chartContainer,
      crosshairEnabled,
      priceInfo,
      timeframes,
      selectedTimeframe,
      isLoading,
      isInitialLoad,
      showMA,
      showVWAP,
      fitContent,
      toggleCrosshair,
      toggleMA,
      toggleVWAP,
      changeTimeframe,
      toolTip,
    };
  },
};
</script>

<style scoped>
.chart-tab {
  padding: 1rem;
  position: relative;
}

.chart-container {
  width: 100%;
  height: 600px;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-content {
  text-align: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

.loading-text {
  font-size: 1rem;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ma-active {
  border: 2px solid #2962ff !important;
  background-color: rgba(41, 98, 255, 0.1) !important;
  color: #2962ff !important;
}

.vwap-active {
  border: 2px solid #9b59b6 !important;
  background-color: rgba(155, 89, 182, 0.1) !important;
  color: #9b59b6 !important;
}
</style>
