<template>
  <span
    v-if="shouldDisplay"
    :class="{ tooltip: shouldShowTooltip }"
    :data-tip="shouldShowTooltip ? tooltipContent : null"
  >
    {{ formattedValue }}
  </span>
  <span v-else></span>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { formatCurrency, currencySymbols } from "@/utils/formatting";

export default {
  name: "CurrencyDisplay",
  props: {
    value: {
      type: [Number, String],
      required: true,
      default: 0,
      validator: (value) => {
        // Allow numbers
        if (typeof value === "number") {
          return !isNaN(value);
        }
        // Allow strings that can be converted to valid numbers
        if (typeof value === "string") {
          const num = Number(value);
          return !isNaN(num);
        }
        return false;
      },
    },
    currency: {
      type: String,
      required: true,
    },
    hideIfZero: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCurrency: {
      type: Boolean,
      required: false,
      default: false,
    },
    tradingPair: {
      type: String,
      required: false,
      default: null,
    },
    isCoin: {
      type: Boolean,
      required: false,
      default: false,
    },
    showingUsdEquivalent: {
      type: Boolean,
      required: false,
      default: false,
    },
    prices: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();

    // Get prices for all supported cryptocurrencies
    const cryptoPrices = computed(() => {
      // If prices are provided via props, use them
      if (Object.keys(props.prices).length > 0) {
        return props.prices;
      }

      // Otherwise, fall back to fetching from store (legacy behavior)
      // Add null check for store module existence
      if (!store._modules.root._children.price) {
        console.error("Price module not registered!");
        return {};
      }

      // Check initialization state first
      if (!store.getters["price/isInitialized"]) {
        return {};
      }

      // Safely get prices with fallbacks
      const getPrice = (symbol) => {
        try {
          return store.getters["price/getPrice"](symbol.toLowerCase()) || 0;
        } catch (e) {
          return 0;
        }
      };

      // Get all crypto symbols from formatting.js (filter out fiat)
      const cryptoCurrencies = Object.keys(currencySymbols).filter(
        (symbol) => !["USD", "EUR"].includes(symbol) // Add other fiats if needed
      );

      // Create dynamic price getter for all crypto currencies
      return cryptoCurrencies.reduce((acc, symbol) => {
        acc[symbol] = getPrice(symbol.toLowerCase());
        return acc;
      }, {});
    });

    const formattedValue = computed(() => {
      try {
        if (!store.getters["price/isInitialized"]) return "";
        if (
          props.value === null ||
          props.value === undefined ||
          isNaN(props.value)
        ) {
          return "—";
        }

        return formatCurrency(props.value, props.currency, store);
      } catch (e) {
        console.error("Formatting error:", e);
        return "—";
      }
    });

    const shouldShowTooltip = computed(() => {
      if (!store._modules.root._children.price) return false;
      if (!props.currency) return false;
      const upperCurrency = props.currency.toUpperCase();

      // Check if currency is a crypto we support (exists in symbols and has price)
      const isCrypto =
        !!currencySymbols[upperCurrency] && upperCurrency !== "USDT";
      const hasPrice = cryptoPrices.value[upperCurrency] > 0;

      return (
        isCrypto &&
        !props.showingUsdEquivalent &&
        hasPrice &&
        upperCurrency !== "USDT"
      ); // Final USDT check
    });

    const tooltipContent = computed(() => {
      if (!shouldShowTooltip.value) return null;

      const upperCurrency = props.currency.toUpperCase();
      const price = cryptoPrices.value[upperCurrency];
      const convertedValue = props.value * price;

      return `≈ $${convertedValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    });

    const shouldDisplay = computed(() => {
      // Check if we should hide when value is zero
      if (props.hideIfZero && (Number(props.value) === 0 || formattedValue.value === "0")) {
        return false;
      }

      return (
        formattedValue.value !== null &&
        formattedValue.value !== undefined &&
        formattedValue.value !== ""
      );
    });

    return {
      formattedValue,
      tooltipContent,
      shouldShowTooltip,
      shouldDisplay,
    };
  },
};
</script>

<style scoped>
.tooltip {
  text-align: inherit;
}
</style>
