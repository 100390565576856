import { createStore } from "vuex";
import auth from "./modules/auth";
import profile from "./modules/profile";
import theme from "./modules/theme";
import bot from "./modules/bot";
import reporting from "./modules/reporting";
import price from "./modules/price";
import symbols from "./modules/symbols";

export default createStore({
  modules: {
    auth,
    profile,
    theme,
    bot,
    reporting,
    price,
    symbols,
  },
});
