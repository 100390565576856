export const currencySymbols = {
  USD: "$",
  EUR: "€",
  BTC: "₿",
  USDT: "$",
  USDC: "$",
  ETH: "⟠",
  SOL: "◎",
  XRP: "X",
  TRUMP: "T",
  PAXG: "G",
};

export const defaultCurrencyRounding = {
  USD: 2,
  EUR: 2,
  BTC: 8,
  USDT: 2,
  USDC: 2,
  ETH: 6,
  SOL: 6,
  XRP: 3,
  TRUMP: 3,
  PAXG: 6,
};

export function calculateDecimalPlaces(pricePerUnit) {
  if (!pricePerUnit || pricePerUnit <= 0) return 2;

  const magnitude = Math.log10(pricePerUnit);
  let decimalPlaces = Math.max(2, Math.floor(magnitude));

  // Adjust for practicality
  if (decimalPlaces > 8) {
    decimalPlaces = 8;
  } else if (decimalPlaces < 2) {
    decimalPlaces = 2;
  }

  return decimalPlaces;
}

export function getCurrencyRounding(currency, store) {
  const upperCurrency = currency?.toUpperCase();

  // First check if we have a hardcoded value
  if (defaultCurrencyRounding[upperCurrency]) {
    return defaultCurrencyRounding[upperCurrency];
  }

  try {
    const price = store?.getters?.["price/getPrice"]?.(
      upperCurrency.toLowerCase()
    );
    if (price) {
      // Calculate decimal places based on price magnitude
      const magnitude = Math.log10(price);
      let decimalPlaces = Math.max(2, Math.floor(magnitude));

      // Ensure decimal places are between 2 and 8
      decimalPlaces = Math.min(Math.max(decimalPlaces, 2), 8);
      return decimalPlaces;
    }
  } catch (error) {
    console.error("Error getting price for currency:", error);
  }

  // Default to 8 decimal places if we can't determine the price
  return 8;
}

export function formatPrice(price, currency, store) {
  if (price === null || price === undefined || isNaN(price)) {
    return `${currencySymbols[currency] || "$"}0`;
  }

  const precision = getCurrencyRounding(currency, store) || 2;
  const hasDecimal = parseFloat(price) % 1 !== 0;

  const formattedValue = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: precision,
    minimumFractionDigits: hasDecimal ? precision : 0,
  }).format(parseFloat(price));

  return `${currencySymbols[currency] || "$"}${formattedValue}`;
}

export function formatCurrency(value, currency, store) {
  if (value === null || value === undefined || isNaN(value)) return null;

  const upperCurrency = currency?.toUpperCase();
  const symbol = currencySymbols[upperCurrency] || upperCurrency || "?";
  const rounding = getCurrencyRounding(upperCurrency, store);

  // Special handling for hardcoded currencies
  if (upperCurrency === "USD" || upperCurrency === "USDT") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  if (upperCurrency === "BTC") {
    return `${symbol}${new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8,
    }).format(value)}`;
  }

  // For non-hardcoded currencies, format as "123 DOGE" instead of "DOGE123"
  if (!currencySymbols[upperCurrency]) {
    const roundedValue = Math.round(value * 10 ** rounding) / 10 ** rounding;
    const formattedAmount = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: rounding,
      minimumFractionDigits: rounding,
      useGrouping: false,
    }).format(roundedValue);
    return `${formattedAmount} ${symbol}`;
  }

  // Default formatting for other hardcoded currencies
  const roundedValue = Math.round(value * 10 ** rounding) / 10 ** rounding;
  const formattedAmount = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: rounding,
    minimumFractionDigits: rounding,
  }).format(roundedValue);

  return `${symbol}${formattedAmount}`;
}

export function formatAmount(amount, currency = null, store) {
  if (amount === null || amount === undefined) {
    return "0";
  }

  const value = parseFloat(amount);
  if (currency) {
    const precision = getCurrencyRounding(currency, store) || 2;
    const formatted = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: precision,
    }).format(value);
    return formatted;
  }

  const str = value.toString();
  const [whole, decimal] = str.split(".");
  if (!decimal) return whole;
  return `${whole}.${decimal.slice(0, 8)}`;
}

export function formatCommission(value, currency) {
  if (value === null || value === undefined) {
    return `0 ${currency}`;
  }
  return `${parseFloat(value).toFixed(8)} ${currency}`;
}

export function formatDate(dateString) {
  if (!dateString) {
    return "";
  }
  return new Date(dateString).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}

export function formatDatetime(dateString) {
  if (!dateString) {
    return "";
  }
  return new Date(dateString).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export function formatDatetimeFromMicro(microTimestamp) {
  if (!microTimestamp) {
    return "";
  }
  return formatDatetime(microTimestamp / 1e6);
}

export function formatDatetimeFromMilli(milliTimestamp) {
  if (!milliTimestamp) return "";

  const timestamp = typeof milliTimestamp === 'string' ? Number(milliTimestamp) : milliTimestamp;
  return formatDatetime(timestamp);
}

export function formatPercent(value) {
  if (value === null || value === undefined) {
    return null;
  }
  return `${parseFloat((parseFloat(value) * 100).toFixed(2)).toString()}%`;
}

export function formatLargeNumber(value, decimals = 2) {
  if (value === null || value === undefined) {
    return "0";
  }
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(parseFloat(value));
}

export function formatCryptoAmount(value, decimals = 8) {
  if (value === null || value === undefined) {
    return "0";
  }
  return parseFloat(value).toFixed(decimals);
}

export function getQuotePrecision(symbol, store) {
  if (!symbol) return 2;
  const [base, quote] = symbol.split("-");

  if (base === "XRP" && quote === "USDT") return 4;

  return getCurrencyRounding(quote, store) || 2;
}

export function formatDuration(timestamp) {
  const start = new Date(timestamp);
  const now = new Date();
  const diffInMs = now - start;

  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  let duration = [];
  if (days > 0) duration.push(`${days}d`);
  if (hours > 0) duration.push(`${hours}h`);
  if (minutes > 0) duration.push(`${minutes}m`);

  return duration.join(" ") || "0m";
}

export function consolidateMultipleCurrencies(
  wallet,
  targetCurrency = "USDT",
  store
) {
  if (!wallet) {
    return 0;
  }
  let totalValue = 0;
  for (const [currency, amount] of Object.entries(wallet)) {
    if (
      currency === targetCurrency ||
      (currency.includes("USD") && targetCurrency.includes("USD"))
    ) {
      totalValue += amount;
      continue;
    }
    const price = store.getters["price/getPrice"](currency.toLowerCase());
    if (price) {
      totalValue += amount * price;
    }
  }
  return totalValue;
}

export function toTitleCase(str) {
  return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
