<template>
  <div
    v-if="strategy?.summary_template"
    class="p-4 bg-base-300 rounded-lg"
  >
    <h4 class="font-bold mb-2">Bot Summary</h4>
    <p class="whitespace-pre-wrap">
      <template v-if="isLoading"> Loading summary... </template>
      <template v-else>
        {{ renderedSummary }}
      </template>
    </p>
  </div>
</template>

<script>
import walletService from "@/services/walletService";
import { getDefaultFieldFormat } from "@/services/smartDefaultService";
import { formatValue } from "@/services/typeService";
import { useStore } from "vuex";

export default {
  name: "BotSummary",
  props: {
    strategy: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    specialFieldValues: {
      type: Object,
      required: true,
    },
    symbol: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();

    const getSymbolInfo = (symbol) => {
      return store.getters["symbols/getSymbolInfo"](symbol);
    };

    return {
      getSymbolInfo,
      store,
    };
  },
  computed: {
    walletType() {
      return this.symbol ? walletService.getWalletType(this.symbol) : null;
    },
    renderedSummary() {
      if (!this.strategy?.summary_template) return "";

      let summary = this.strategy.summary_template;

      // Replace field values
      Object.entries(this.config).forEach(([key, value]) => {
        const field = this.strategy.fields.find((f) => f.name === key);
        if (field) {
          const formattedValue = this.formatValue(
            value,
            field.type,
            field.name
          );
          summary = summary.replaceAll(`{${key}}`, formattedValue);
        }
      });

      // Handle special and custom fields
      if (this.strategy.custom_fields) {
        this.strategy.custom_fields.forEach((field) => {
          const value =
            field.type === "special"
              ? this.specialFieldValues[field.name]
              : this.calculateCustomFieldValue(field, new Set());

          if (value !== undefined) {
            const formattedValue = this.formatValue(
              value,
              field.type,
              field.name
            );
            summary = summary.replaceAll(`{${field.name}}`, formattedValue);
          }
        });
      }

      return summary;
    },
    isLoading() {
      // Check if we have all required special fields
      const requiredFields =
        this.strategy.custom_fields
          ?.filter((f) => f.type === "special")
          ?.map((f) => f.name) || [];

      return requiredFields.some((field) => !this.specialFieldValues[field]);
    },
  },
  watch: {
    // Watch for changes in config or special field values
    config: {
      deep: true,
      handler() {
        // Force recompute of renderedSummary
        this.$forceUpdate();
      },
    },
    specialFieldValues: {
      deep: true,
      handler() {
        this.$forceUpdate();
      },
    },
  },
  methods: {
    formatValue(value, type, fieldName) {
      if (
        value === undefined ||
        value === null ||
        (type !== "string" &&
          type !== "dropdown" &&
          (isNaN(Number(value)) || !isFinite(value)))
      )
        return "...";

      // Special handling for dropdown type
      if (type === "dropdown") {
        // In the bot summary, value should be the selected option
        return value?.toString() || "";
      }

      // Limit large numbers
      if (type !== "string") {
        value = Number(value);
        if (Math.abs(value) > 1e15) {
          return "...";
        }
      }

      // Special handling for order_size based on wallet type
      if (fieldName === "order_size") {
        // Check for default field formatting with wallet type
        const defaultFormatting = getDefaultFieldFormat(
          fieldName,
          value,
          this.symbol,
          this.walletType // Pass the wallet type
        );
        if (defaultFormatting) {
          return defaultFormatting;
        }
      } else {
        // For other fields, use normal default formatting
        const defaultFormatting = getDefaultFieldFormat(
          fieldName,
          value,
          this.symbol
        );
        if (defaultFormatting) {
          return defaultFormatting;
        }
      }

      // Use typeService formatting with symbol and wallet type
      return formatValue(value, type, this.symbol, this.store); // Pass this.store
    },

    replaceFieldInFormula(formula, key, value, errorMsg = null) {
      const placeholder = new RegExp(`\\{${key}\\}`, "g");
      if (formula.match(placeholder)) {
        if (errorMsg && (!value || value === undefined)) {
          throw new Error(errorMsg);
        }
        // Ensure value is a valid number and not too large
        const numValue = Number(value);
        if (isNaN(numValue) || !isFinite(numValue)) {
          throw new Error(`Invalid number value for ${key}`);
        }
        return formula.replace(placeholder, `(${numValue})`);
      }
      return formula;
    },

    calculateCustomFieldValue(field, visited = new Set()) {
      try {
        if (visited.has(field.name)) {
          return "?";
        }
        visited.add(field.name);

        let formula = field.formula;

        // Replace config values
        for (const [key, value] of Object.entries(this.config)) {
          formula = this.replaceFieldInFormula(
            formula,
            key,
            value,
            `Missing value for ${key}`
          );
        }

        // Replace special field values
        if (this.strategy.custom_fields) {
          const specialFields = this.strategy.custom_fields.filter(
            (f) => f.type === "special"
          );
          for (const specialField of specialFields) {
            formula = this.replaceFieldInFormula(
              formula,
              specialField.name,
              this.specialFieldValues[specialField.name],
              `Special field ${specialField.name} not loaded`
            );
          }
        }

        // Replace other custom field references
        const customFields = (this.strategy.custom_fields || []).filter(
          (f) => f.type !== "special" && f.name !== field.name
        );
        for (const customField of customFields) {
          const value = this.calculateCustomFieldValue(
            customField,
            new Set(visited)
          );
          formula = this.replaceFieldInFormula(
            formula,
            customField.name,
            value,
            `Invalid reference to ${customField.name}`
          );
        }

        const result = new Function(`return ${formula}`)();
        // Check if result is valid
        if (!isFinite(result) || isNaN(result)) {
          return "?";
        }

        // Return the raw numeric value - formatting will be handled by formatValue
        return result;
      } catch (error) {
        console.error(`Error calculating ${field.name}:`, error);
        return "?";
      } finally {
        visited.delete(field.name);
      }
    },
  },
};
</script>
